import React, { FC, useState } from "react";
import Header from "./Header/Header";
import * as styles from "./Layout.module.scss";
import FooterShort from "./Footer/FooterShort";
import MobileModal from "../Shared/MobileModal/MobileModal";
import CookieConsent from "../Shared/CookieConsent/CookieConsent";
import LanguageProvider from "../../context/languageContext";

type Props = {
  children: any;
  location: any;
  isModalHidden?: boolean;
  setIsModalHidden?: (x: boolean) => void;
};

const Layout: FC<Props> = ({ children }) => {
  const [isMenuShown, setIsMenuShown] = useState(false);

  return (
    <LanguageProvider>
      <div className={styles.layoutContainer}>
        {!isMenuShown && (
          <Header
            isModalHidden={isMenuShown}
            setIsModalHidden={setIsMenuShown}
          />
        )}
        {!isMenuShown && children}
        {isMenuShown && (
          <MobileModal
            isModalHidden={isMenuShown}
            setIsModalHidden={setIsMenuShown}
            modalContent={""}
          />
        )}
        <FooterShort />
        {/* <CookieConsent
          buttonText={translate("cookie-btn")}
          declineButtonText={translate("cookie-btn-decline")}
          enableDeclineButton
          onAccept={() => {
            function consentGranted() {
              dataLayer.push("consent", "update", {
                ad_storage: "granted",
                analytics_storage: "granted",
                functionality_storage: "granted",
                personalization_storage: "granted",
                security_storage: "granted",
                wait_for_update: "500",
              });
            }
            consentGranted();
          }}
          onDecline={() => {
            function consentDefault() {
              dataLayer.push("consent", "default", {
                ad_storage: "denied",
                analytics_storage: "denied",
                functionality_storage: "denied",
                wait_for_update: "500",
              });
            }
            consentDefault();
          }}
        >
          <b>{translate("cookie-text-one")}</b>
          <br /> <br />
          {translate("cookie-text-two")}
          <br />
          {translate("cookie-text-three")}{" "}
          <Link style={{ color: "#00ecbd" }} to="/privacy-policy">
            {translate("cookie-text-four")}{" "}
          </Link>
        </CookieConsent> */}
        <CookieConsent />
      </div>
    </LanguageProvider>
  );
};

export default Layout;
