import React from "react";
import * as styles from "./FooterShort.module.scss";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import globaldatanetlogo from "../../../images/gdn-x1f.svg";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import instagram from "../../../images/instagram.svg";
import facebook from "../../../images/facebook.svg";
import twitter from "../../../images/twitter.svg";
import github from "../../../images/github.svg";
import linkedin from "../../../images/linkedin.svg";
import twitch from "../../../images/twitch.svg";
import podcast from "../../../images/podcast.svg";
import rss from "../../../images/rss.svg";
import FooterItem from "./FooterItem/FooterItem";
import {
  NavigationItems,
  OurCompanyItems,
  SolutionsItems,
} from "./Footer.helper";
import { Link } from "gatsby";
import FooterItemsForMobile from "./FooterItemsForMobile";
import { UseWindowSize } from "../../hooks/UseWindowSize";
import CircleBlur from "../../Shared/CircleOutlineAndBlur/CircleBlur";
import CircleOutline from "../../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleGreenBlur from "../../Shared/CircleOutlineAndBlur/Circlegreenblur";
import translate from "../../../i18n/translate";

const FooterShort = () => {
  const width = UseWindowSize();

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerContent}>
        <div className={styles.footerContentBottomNavigation}>
          <div className={styles.footerContentBottomNavigationLogo}>
            <img
              src={globaldatanetlogo}
              width="250px"
              height="auto"
              alt="globaldatanet"
              className={styles.contentLogo}
            />
            <span className={styles.footerContentBottomNavigationDescription}>
              Cloud Development, Optimization & Automation
            </span>
            <CustomButton
              buttonClass={styles.buttonClasses}
              text={translate("home-contactus")}
              image={arrowRightWhite}
              linkPath={"/contact"}
            />
          </div>
          {width.width > 1200 ? (
            <>
              <FooterItem
                title={"Solutions"}
                navigationTitles={SolutionsItems}
                classNameOfFooterItem={styles.footerItemContainer}
              />
              <FooterItem
                title={"Content hub"}
                navigationTitles={NavigationItems}
                classNameOfFooterItem={styles.footerItemContainer}
              />
              <FooterItem
                title={"Company"}
                navigationTitles={OurCompanyItems}
                classNameOfFooterItem={styles.footerItemContainer}
              />
            </>
          ) : (
            <FooterItemsForMobile />
          )}
          <div className={styles.footerContentBottomNavigationSocial}>
            <h4 className="heading">
              <span>.</span>Social
            </h4>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/globaldatanet/"
                  target="blank"
                >
                  <img
                    src={instagram}
                    alt="follow globaldatanet on instagram"
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/globaldatanet/"
                  target="blank"
                >
                  <img src={facebook} alt="follow globaldatanet on facebook" />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/globaldatanet" target="blank">
                  <img src={twitter} alt="follow globaldatanet on twitter" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/globaldatanet/"
                  target="blank"
                >
                  <img src={linkedin} alt="follow globaldatanet on linkendin" />
                </a>
              </li>
              <li>
                <a href="https://www.twitch.tv/globaldatanet/" target="blank">
                  <img src={twitch} alt="follow globaldatanet on twitch" />
                </a>
              </li>

              <li>
                <a href="https://globaldatanet.podbean.com/" target="blank">
                  <img
                    src={podcast}
                    alt=" listen to our serverless world podcast"
                  />
                </a>
              </li>
              <li>
                <a href="/tech-blog.xml" target="blank">
                  <img src={rss} alt="follow globaldatanet's tech rss feed" />
                </a>
              </li>
              <li>
                <a href="https://github.com/globaldatanet" target="blank">
                  <img src={github} alt="follow globaldatanet at github" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className={styles.circleGreenBlurDiv}>
          <CircleGreenBlur
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleGreen}
          />
        </div>
      </div>

      <div className={styles.footerInfo}>
        <span>
          © {new Date().getFullYear()} by globaldatanet. All Right Reserved
        </span>
        <div className={styles.footerImprintAndPolicy}>
          <Link to="/imprint">
            <span>Imprint</span>
          </Link>
          <Link to="/privacy-policy">
            <span>Privacy Policy</span>
          </Link>
        </div>
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={false}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.contactOutlineCircle}
          />
          <CircleBlur
            initialOpacity={0}
            animateOpacity={0.1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurGreen}
          />

          <CircleBlur
            initialOpacity={0}
            animateOpacity={0.1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.aboutMainCircleBlurBlue}
          />
        </div>
      </div>
    </div>
  );
};

export default FooterShort;
