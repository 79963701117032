import React, { FC } from "react";

type Props = {
  item: any;
  onSelectLanguage: (arg1: string) => void;
};

const LanguageItem: FC<Props> = ({ item, onSelectLanguage }) => {
  return (
    <li>
      <span
        style={{ cursor: "pointer", marginTop: 0 }}
        onClick={() => {
          onSelectLanguage(item.title);
          localStorage.setItem("lang", item.title);
        }}
      >
        .{item.title}
      </span>
    </li>
  );
};

export default LanguageItem;
