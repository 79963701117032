import { LOCALES } from "../locales";

export default {
  [LOCALES.EN]: {
    "home-intro": ".More business .Made in the Cloud",
    "home-description":
      "Innovation and efficiency with award-winning cloud solutions",
    "home-whoweare": "About us",
    "home-contactus": "Contact us",
    "home-4years": "6 years",
    "home-yearsDescription":
      "globaldatanet is a leading cloud-native company with the mission to build and modernize next-generation applications and platforms made in and for the cloud. For more than 6 years, globaldatanet has been helping other businesses adopt cutting-edge technologies to solve challenges and create new opportunities.",
    "home-aboutAws":
      "Award-winning AWS Advanced Consulting Partner with a full focus on Amazon Web Services cloud. With our experienced and certified team of cloud specialists, we have been a strong partner in the development and modernization of cloud-native applications since day one.",
    "home-ourServiceGeneral": ".Our service",
    "home-ourService": "01. Our Services",
    "home-ourService2": "02. Our Services",
    "home-ourService3": "03. Our Services",
    "home-development": "Development",
    "home-optimize": "Optimize",
    "home-automate": "Automate",
    "home-developmentDescription":
      "To succeed in fast-paced, software-driven markets, companies must change the way they design, build, and use applications. Cloud-native application development is an approach to building, running, and improving apps based on well-known techniques and technologies for cloud computing. Cloud-native app development typically includes devops, agile methodology, microservices, cloud platforms, containers, serverless and continuous delivery —in short, every new and modern method of application deployment.",
    "home-optimizeDescription":
      "Cloud computing requires a new way of thinking about architecture and design. We understand that staying up to date with the pace of AWS innovation can be a challenge and requires expert knowledge and a set of best practices to get the most out of your architecture. We evaluate your cloud environment, report on critical security gaps, recommend remediation steps, and re-architect for maximum efficiency and highest security.",
    "home-automateDescription":
      "Cloud automation is the use of automated tools and processes to execute workflows in a cloud environment that would otherwise have to be performed manually by your engineers. Instead of wasting time and resources managing cloud environments by hand, organisations that leverage cloud automation are able to focus their resources on activities that deliver direct business benefits, like developing new services and keeping customers pleased. Cloud automation is the only way to leverage the most value out of cloud environments.",
    "home-readmore": "Read more",
    "home-ourCustomers": "Our Customers",
    "home-caseStudies": "Case Studies",
    "home-stayTuned": ".Stay tuned",
    "home-subscribe": "Subscribe to get the latest insights on the cloud",
    "header-company": "company",
    "header-sustainability": "sustainability",
    "header-optimizeHeader": "optimize",
    "header-developmentHeader": "development",
    "header-automateHeader": "automate",
    "header-contentHub": "content hub",
    "header-contact": "contact",
    "header-webinars": "webinars",
    "header-reports": "reports",
    "header-caseStudies": "case studies",
    "header-cloudInsights": "cloud insights",
    "header-partners": "partners",
    "header-ourTeam": "our team",
    "header-ourStory": "our story",
    "header-news": "news",
    "header-careers": "careers",
    "header-techBlog": "tech blog",
    "header-opensource": "open source",
    "header-community": "community",
    "optimization-intro": ".Cloud Optimization",
    "optimization-description": "Boost up efficiency and lower the risks.​",
    "optimization-improvement": "Continuous Improvement​",
    "optimization-improvementDescription":
      "Cloud computing requires a new way of thinking about architecture and design. We understand that staying up to date with the pace of AWS innovation can be a challenge and requires expert knowledge and a set of best practices to get the most out of your architecture. We evaluate your cloud environment, report on critical security gaps, recommend remediation steps, and re-architect for maximum efficiency and highest security.",
    "optimization-pillars": "Pillars",
    "optimization-pillarsTitle1": ".Operational Excellence",
    "optimization-pillarsTitle2": ".Security",
    "optimization-pillarsTitle3": ".Reliability",
    "optimization-pillarsTitle4": ".Efficiency",
    "optimization-pillarsTitle5": ".Cost optimization",
    "optimization-pillarsDescription1":
      "Here the optimization of operations and the monitoring of the systems are in the foreground. The goal is the continuous improvement of the value chain through optimized structures and processes.",
    "optimization-pillarsDescription2":
      "The protection of data and systems is of the highest priority. It concerns the measures for safeguarding confidentiality and data integrity, such as through suitable rights management or identifying security breaches through corresponding checks.",
    "optimization-pillarsDescription3":
      "Error notification and prompt, automated restoration of systems in the event of an outage ensure high reliability. The architecture is designed to recognize bottlenecks in resources early on and request capacities.",
    "optimization-pillarsDescription4":
      "Optimal usage of AWS resources, including for fluctuating demand and various system requirements and technologies. The selection of the right services and types of resources, in addition to the consideration of modern architectural paradigms, such as serverless, for example, are the focal point here.",
    "optimization-pillarsDescription5":
      "An extensive analysis and adaptation of resources takes place to avoid/reduce unnecessary costs. Capacity reservations and the optimization of resources are possible steps here.",
    "optimization-benefits": ".Benefits",
    "optimization-benefitsHeading1": "Quicker development and provision",
    "optimization-benefitsHeading2": "Reduction of risk",
    "optimization-benefitsHeading3": "Well-founded decisions ",
    "optimization-benefitsHeading4": "Learning from best practices",
    "optimization-benefitsHeading5": "Continuous improvement plan",
    "optimization-benefitsDescription1":
      "Continuous feedback and automation ensure faster provision of application changes and new features.",
    "optimization-benefitsDescription2":
      "Protecting information and systems including confidentiality and integrity of data, privilege management, establishing controls to detect security events.",
    "optimization-benefitsDescription3":
      "Understand the pros and cons of decisions you make while building systems on AWS.",
    "optimization-benefitsDescription4":
      "The AWS Well-Architected Framework is a set of best practices that guide you in building resilient and agile applications on the cloud.",
    "optimization-benefitsDescription5":
      "Adopting AWS architectural best practices enables you to save time, costs, drive efficiency and provide you fact-based improvement plan.",
    "optimization-ourApproach": "Our Approach",
    "optimization-ourApproachHeading1": "Prepare",
    "optimization-ourApproachHeading2": "Review",
    "optimization-ourApproachHeading3": "Optimize",
    "optimization-ourApproachDescription1":
      "Selection of the applications to be optimized, answering queries and discussion of additional framework conditions.",
    "optimization-ourApproachDescription2":
      "Architecture, and requirements will be considered and discussed in detail, in accordance with the AWS Well-Architected Framework.",
    "optimization-ourApproachDescription3":
      "Results are evaluated and concrete improvement measures are developed. Implementation of the medium and long-term measures.",
    "optimization-cloudInsights": "Explore Our Latest Cloud Insights",
    "optimization-showAll": "Show all",
    "optimization-contactHeading": "Optimization and modernization",
    "contact-lookingFor":
      "Are you looking for a team that goes a step further? We are ready for all tasks!",
    "development-heading": ".Cloud-Native Development",
    "development-description":
      "The next generation of applications are built cloud-native.",
    "development-buildCloud": "Build for the Cloud",
    "development-buildCloudDescription":
      "To succeed in fast-paced, software-driven markets, companies must change the way they design, build, and use applications. Cloud-native application development is an approach to building, running, and improving apps based on well-known techniques and technologies for cloud computing. Cloud-native app development typically includes devops, agile methodology, microservices, cloud platforms, containers, serverless and continuous delivery —in short, every new and modern method of application deployment.",
    "development-benefitsHeading1": "Decreased time to market",
    "development-benefitsHeading2": "Shorten the release cycles",
    "development-benefitsHeading3": "Scalability and Resilience",
    "development-benefitsHeading4": "Cost efficiency",
    "development-benefitsHeading5": "Unmatched Agility",
    "development-benefitsDescription1":
      "Cloud Native architecture enables increased agility and extensibility, as companies can add or update new modules without having to rebuild entire applications.",
    "development-benefitsDescription2":
      "Leverage industry leading cloud native design patterns, modern application development, 12 factor app development principles, along with Agile and DevOps enablement.",
    "development-benefitsDescription3":
      "Scale seamlessly on-demand through unpredictable traffic and remain resilient in the face of unexpected failures, without any effort.",
    "development-benefitsDescription4":
      "With dynamic scaling, you can increase or decrease computing resources as required depending on current application usage.",
    "development-benefitsDescription5":
      "Reduce development overhead, time and effort and reclaim energy that can be spent innovating and building amazing products.",
    "development-ourApproachHeading1": "Focus on serverless",
    "development-ourApproachHeading2": "End-to-End Service",
    "development-ourApproachHeading3": "Sprint-Based & Agile",
    "development-ourApproachDescription1":
      "Dedicated architects and engineers with focus and expertise exclusively on serverless application development.",
    "development-ourApproachDescription2":
      "Delivery of all backend applications, APIs, integrations, and UI to turn your idea into a fully-realized application.",
    "development-ourApproachDescription3":
      "Collaborative and flexible approach that adapts quickly to overcome any new challenges and ensure project success.",
    "automate-heading": ".Automation on AWS Cloud",
    "automate-description":
      ".Accelerate, secure and modernize software delivery​",
    "automate-bannerHeading": "Driving Agility, Scale and Control",
    "automate-bannerDescription":
      "Cloud automation is the use of automated tools and processes to execute workflows in a cloud environment that would otherwise have to be performed manually by your engineers. Instead of wasting time and resources managing cloud environments by hand, organisations that leverage cloud automation are able to focus their resources on activities that deliver direct business benefits, like developing new services and keeping customers pleased. Cloud automation is the only way to leverage the most value out of cloud environments.",
    "automate-service": "Automation Services",
    "automate-serviceDescription1":
      "DevOps automation is the practice of automating repetitive and manual DevOps tasks to be carried out without any human interaction. Automation can be applied throughout the DevOps lifecycle: Design and development, Software deployment and release, Monitoring & Logging. The goal of DevOps automation is to streamline the DevOps lifecycle by reducing manual workload.",
    "automate-serviceDescription2":
      "DevSecOps means building security into app development from end to end. This integration into the pipeline requires a new organizational mindset as much as it does new tools. Automate security to protect the overall environment and data, as well as the continuous integration/continuous delivery process — a goal that will likely include the security of microservices in containers and Serverless.",
    "automate-serviceDescription3":
      "Improve the automation, governance, and quality of production ML through Continuous Delivery (CD) pipelines and Self-Service Portals. An ML pipeline in production continuously delivers prediction services to new models that are trained on new data. The model deployment step, which serves the trained and validated model as an inference service for online inferences, is automated.",
    "automate-benefitsHeading1": "Improve Collaboration",
    "automate-benefitsHeading2": "Lower Costs",
    "automate-benefitsHeading3": "Accelerated Innovation",
    "automate-benefitsHeading4": "Increase efficiency",
    "automate-benefitsHeading5": "Reduce failures",
    "automate-benefitsHeading6": "Better Quality Products",
    "automate-benefitsDescription1":
      "Eliminates typical handoff challenges between different roles with an automated and coordinated process.",
    "automate-benefitsDescription2":
      "Reduces manual tasks allowing you to increase your development capacity and reduce errors.",
    "automate-benefitsDescription3":
      "Integrated operations and development team leads to faster development and deployment.",
    "automate-benefitsDescription4":
      "Automated tools and standardized production platforms make deployments more predictable. ",
    "automate-benefitsDescription5":
      "When changes are small and distinct rather than large and sweeping, they are safer. Not only the chance of failure decreases, but the recovery time also decreases. ",
    "automate-benefitsDescription6":
      "Products are deployed with fewer bugs, and since deployment is frequent and cycle times are shorter, products can be continuously improved more quickly.",
    "automate-ourApproachHeading1": "Discover",
    "automate-ourApproachHeading2": "Build",
    "automate-ourApproachHeading3": "Enable",
    "automate-ourApproachDescription1":
      "Evaluation how the customer currently handles building, testing, deploying, and releasing their applications to production.",
    "automate-ourApproachDescription2":
      "Developing the automation platform on AWS with fully automated infrastructure and deployment pipelines on AWS.",
    "automate-ourApproachDescription3":
      "Expert-led guidance and enablement program. Experienced engineers will upskill your team to do deployments at scale.",
    "partners-heading": ".Partners",
    "partners-description":
      "Each one of us can make a difference - together we make change. Our strong partner alliance.",
    "partners-awsDescription":
      "Amazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud platform, offering over 200 fully featured services from data centers globally - on-demand, available in seconds, with pay-as-you-go pricing. As an AWS Advanced Consulting Partner, globaldatanet is awarded with the AWS DevOps Competency.  As an AWS Well-Architected Framework Partner, we support our partners in optimising their cloud-native applications in the areas of security, costs, reliability, operational excellence and performance.",
    "partners-learnMore": "Learn more",
    "partners-techHeading": "Technology",
    "partners-techPartner1":
      "HashiCorp is the leader in infrastructure automation for multi-cloud environments. They are building solutions that enable innovation at global enterprises, offering them a common cloud operating model via consistent workflows to provision, secure, connect, and run their infrastructure with any application. All foundational technologies are open source and developed openly, and have been since our founding.",
    "partners-techPartner2":
      "Datadog is the monitoring, security and analytics platform for cloud applications. The SaaS platform integrates and automates infrastructure monitoring, application performance monitoring and log management to provide unified, real-time observability of your entire technology stack.",
    "partners-techPartner3":
      "Sysdig is a provider of both cloud and container security for enterprises and their DevOps teams. It drives secure DevOps movement, helping organizations to confidently secure containers, Kubernetes, and cloud services. With the Sysdig Secure DevOps Platform, cloud teams secure the build pipeline, detect and respond to runtime threats, continuously validate compliance, and monitor and troubleshoot cloud infrastructure and services.",
    "partners-techPartner4":
      "OHTRU is a next-generation FinOps Platform Collaborative, Real-Time Cloud Financial Management and Cloud Cost Optimization across multiple clouds, teams and accounts. OHTRU helps SRE, DevOps, and FinOps teams to build more efficient architectures, map costs to the business and cut the cloud costs.",
    "partners-techPartner5":
      "Hasura makes data access easy, by instantly composing a GraphQL API that is backed by databases and services. Developers can build business logic that publishes and consumes events, that reacts to changes in data or gets scheduled based on time. Hasura automates 50-80% of your API development work, making your application development or modernisation project tractable and within a much shorter timeframe.",
    "partners-businessPartner1":
      "Due to increasing specialization and a lack of IT specialists, it is becoming more and more important for companies to find external experts and resources quickly and efficiently and to integrate them into ongoing projects. The Software Alliance Germany is a formal association of 120 medium-sized IT service providers and supports companies and organizations on site",
    "partners-businessPartner2":
      "EuroCloud Native (ECN) is an initiative of EuroCloud Germany, the association of the cloud computing industry in Germany. The initiative represents the cloud-native industry in Germany, makes the market transparent and brings providers, users and hyperscalers into conversation. In addition to a specialist forum, ECN also sees itself as a contact point for the media.",
    "partners-businessPartner3":
      "realizon is building dedicated engineering teams for you. They provide you with everything you need to successfully expand your existing project or start from scratch and turn a great concept into a superior, highly competitive software product. Their entire focus is aimed at creating excellence in software development: For their clients they achieve outstanding results by creating an environment, which attracts and retains some of the best software developers in the world, thus providing their clients with the competitive edge they require to succeed.",
    "partners-businessPartner4":
      "Ginkgo Analytics is a spin-off from Ginkgo Management Consulting based in Hamburg. They advise companies in the areas of data analytics, big data and machine learning and deliver productive solutions with added value. The mission of Ginkgo Analytics is to transform their customers into data-driven and forward-looking companies.",
    "partners-businessPartner5":
      "wherever SIM stands for reliable and secure M2M SIM cards that connect a wide variety of IoT devices worldwide to the Internet. Thanks to national roaming, their SIM cards always automatically dial into the best mobile network at a location. Combined with flexible data tariffs tailored to the needs of each project and comprehensive hardware expertise, we offer a suitable solution for every project.",
    "ourstory-title": ".Our Story",
    "ourstory-description":
      "With more than 4 years of experience, we have gained in-depth knowledge in the development and modernization of cloud applications.",
    "ourstory-beUniqueHeading": "Be unique and think differently",
    "ourstory-beUniqueDescription":
      "globaldatanet is a cloud-native company that is specialized in the development and optimization of platforms and applications in the AWS cloud. We enable customers around the world to use the full potential of the cloud, to increase revenue with new products or to increase efficiency by improving existing applications. We rely on pragmatic solutions that are precisely tailored to the needs of our customers without losing sight of simplicity and efficiency. What makes us unique We think differently, we value long-term partnerships and, above all, we love what we do and we put this passion into our projects.",
    "ourstory-ourTeam": "Our Team",
    "ourstory-joinOurTeamHeading": "Join our Team",
    "ourstory-joinOurTeamDescription1":
      ".Do things better. Then do better things.",
    "ourstory-joinOurTeamDescription2": "Then do better things.",
    "ourstory-searchCareer": "Search Career",
    "ourstory-behindSceneHeading": "Behind the scenes, the drivers behind",
    "ourstory-behindSceneDescription":
      "The story behind our innovative cloud solutions begins with our team. We are a team of versatile specialists not generalists. Everyone focuses on their core areas building up in depth expert knowledge, ensuring highest quality and deep expertise in a variety of areas.",
    "ourstory-cloudValue": "Our Cloudy Values:",
    "ourstory-cloudValueDescription":
      "6 values are not a lot to describe us, but it's a start",
    "ourstory-cloudValueHeading1": ".Love what you do",
    "ourstory-cloudValueHeading2": ".Learn and be curious",
    "ourstory-cloudValueHeading3": ".People first",
    "ourstory-cloudValueHeading4": ".Trust",
    "ourstory-cloudValueHeading5": ".Invent & Simplify",
    "ourstory-cloudValueHeading6": ".Crazy Ideas & Creativity",
    "ourstory-cloudValueDesc1":
      "Talent without passion is like having a Lambda function without any argument.",
    "ourstory-cloudValueDesc2":
      "How to get better? Motivation, curiosity, passion and striving for new technologies. That's all you need!.",
    "ourstory-cloudValueDesc3":
      "The team is the most important resource. Care for each other, support each other and have fun together.",
    "ourstory-cloudValueDesc4":
      "Longstanding partnerships are based on trust, transparency and honesty. This is what we value.",
    "ourstory-cloudValueDesc5":
      "Nothing worse than going out with an over engineered app. We love to invent innovative solutions while keeping things simple and efficient.",
    "ourstory-cloudValueDesc6":
      "Run of the mill? Not in our company! We think differently and like crazy ideas, creating something special and unique.",
    "ourstory-newsRoom": "View our Newsroom",
    "ourstory-viewAll": "View all",
    "careers-title": ".Become a creator",
    "careers-description":
      "Are you looking for a new challenge? See our open positions, apply and be part of our journey.",
    "careers-apply": "Apply now",
    "careers-thatUsHeading": "That's us",
    "careers-thatUsDesc":
      "The team is the most important factor. We love what we do and we love to build innovative solutions and do things differently than others. Working together at globaldatanet means supporting each other, having fun together and being unique.",
    "careers-coreValue": "Core Values",
    "careers-coreValue1": "Love what you do",
    "careers-coreValue2": "Support each other",
    "careers-coreValue3": "Learn and be curious",
    "careers-coreValue4": "People first",
    "careers-coreValue5": "Trust",
    "careers-coreValue6": "Invent & Simplify",
    "careers-coreValue7": "Crazy Ideas & Creativity",
    "careers-coreValue8": "Make it fun",
    "careers-coreValue9": "Community Contribution",
    "careers-benefits": "Your Benefits",
    "careers-benefitsHeading1": "Culture & Development",
    "careers-benefitsHeading2": "Community",
    "careers-benefitsHeading3": "Work-Life",
    "careers-culture1": "Open feedback culture & space for innovation",
    "careers-culture2": "Agile working methods",
    "careers-culture3": "Training budget & Certification rewards",
    "careers-culture4":
      "Flat hierarchies for creative ideas and unconventional approaches",
    "careers-culture5": "Work with Cutting-Edge Technologies",
    "careers-culture6": "Fast and clear decision-making processes",
    "careers-culture7": "Working with friends",
    "careers-community1": "Unforgettable events & parties",
    "careers-community2": "Weekly Tech & Beer",
    "careers-community3": "Top of the line equipment",
    "careers-community4": "Monthly choice of gift vouchers",
    "careers-community5": "Tank top or Sandals - be yourself",
    "careers-community6": "Visit your friends at our different sites",
    "careers-community7": "All you can drink & snacks",
    "careers-workLife1":
      "Life happens, and work can’t always come first. We support you in finding a way to balance the two",
    "careers-workLife2": "Choose your work & office time flexible",
    "careers-workLife3": "28 days vacation + 24.12 & 31.12 days off",
    "careers-workLife4": "Weekly happy hours",
    "careers-workLife5": "No travelling needed",
    "careers-workLife6": "Dog-friendly office",
    "careers-inNunbers1": "Talents",
    "careers-inNunbers2": "Locations",
    "careers-inNunbers3": "Tech Ratio",
    "careers-inNunbers4": "APN ambassador",
    "careers-inNunbers5": "Server",
    "careers-inNunbers6": "AWS Community Builders",
    "careers-inNunbers7": "Languages",
    "careers-inNunbers8": "Certifications",
    "careers-process": ".Application process",
    "careers-processHeading1": ".Apply Online",
    "careers-processHeading2": ".Intro Call",
    "careers-processHeading3": ".Technical Interview",
    "careers-processHeading4": ".Second Interview",
    "careers-processHeading5": ".Offer + Contract",
    "careers-processDesc1":
      "For applying you simply need to upload your CV. Additionally we are using a short online questionnaire to get to know you even better.",
    "careers-processDesc2":
      "The Intro Call mainly serves to give you a better overview of globaldatanet and everything related to us.",
    "careers-processDesc3":
      "During the technical interview we will mostly dive into a technical discussion, assessing your experience with different technologies in a relaxed atmosphere.",
    "careers-processDesc4":
      "The 2nd Interview is mainly a mix of the Calls before. Our CEO will give you an in depth overview of our vision and we will address some technical and non technical questions.",
    "careers-processDesc5":
      "We address the approximate conditions in the first phase. We give you a first offer. If all conditions are agreed we will finalize the contract and Welcome to our team!",
    "careers-insights": "Insights",
    "careers-inNumbers": "In numbers",
    "contact-title": ".Contact us",
    "contact-description":
      "Are you looking for a team that goes a step further? We are ready for all tasks!",
    "contact-submit": "Submit",
    "contact-writeUs": "Write us",
    "contact-allow":
      "I allow this website to store my submission so they can respond to my inquiry.*",
    "contact-firstName": "First name*",
    "contact-lastName": "Last name*",
    "contact-email": "Email*",
    "contact-phone": "Phone",
    "contact-company": "Company",
    "contact-message": "Message*",
    "contact-automateHeading": "Bring your application to a new level",
    "contact-caseStudies": "Ready to take your business to the next level?",
    "contact-optimizeHeading": "Optimization and modernization",
    "contact-developmentHeading": "Let's discuss your new application",
    "imprint-title": "Imprint",
    "imprint-info": "Information required under § 5 Para. 1 DDG",
    "imprint-chief": "Presented by:",
    "imprint-contact": "Contact:",
    "imprint-phone": "Phone",
    "imprint-email": "Email:",
    "imprint-register": "Registration",
    "imprint-court": "Court:",
    "imprint-number": "Number:",
    "imprint-tax": "Tax",
    "imprint-taxID": "Tax ID:",
    "imprint-taxNo": "Tax number:",
    "privacy-title": "Privacy Policy",
    "privacy-text1-1":
      'Personal data (usually referred to just as "data" below) will only be processed by us to the extent necessary and for the purpose of providing a functional and user-friendly website, including its contents, and the services offered there.',
    "privacy-text1-2":
      'Per Art. 4 No. 1 of Regulation (EU) 2016/679, i.e. the General Data Protection Regulation (hereinafter referred to as the "GDPR"),"processing" refers to any operation or set of operations such as collection, recording, organization, structuring, storage, adaptation, alteration, retrieval, consultation, use, disclosure by transmission, dissemination, or otherwise making available, alignment, or combination, restriction, erasure, or destruction performed on personal data, whether by automated means or not.',
    "privacy-text1-3":
      " The following privacy policy is intended to inform you in particular about the type, scope, purpose, duration, and legal basis for the processing of such data either under our own control or in conjunction with others. We also inform you below about the third-party components we use to optimize our website and improve the user experience which may result in said third parties also processing data they collect and control.",
    "privacy-structure": "Our privacy policy is structured as follows:",
    "privacy-structure1": "Information about us as controllers of your data",
    "privacy-structure2": "Information about us as controllers of your data",
    "privacy-structure3": "Information about the data processing",
    "privacy-sec1Heading": "Information about us as controllers of your data",
    "privacy-sec1Desc":
      'The party responsible for this website (the "controller") for purposes of data protection law is:',
    "privacy-sec1Phone": "Telephone:",
    "privacy-sec2Heading": "The rights of users and data subjects",
    "privacy-sec2list1":
      "With regard to the data processing to be described in more detail below, users and data subjects have the right to confirmation of whether data concerning them is being processed, information about the data being processed, further information about the nature of the data processing, and copies of the data (cf. also Art. 15 GDPR);",
    "privacy-sec2list2":
      "To correct or complete incorrect or incomplete data (cf. also Art. 16 GDPR);",
    "privacy-sec2list3":
      "to the immediate deletion of data concerning them (cf. also Art. 17 DSGVO), or, alternatively, if further processing is necessary as stipulated in Art. 17 Para. 3 GDPR, to restrict said processing per Art. 18 GDPR;",
    "privacy-sec2list4":
      "to receive copies of the data concerning them and/or provided by them and to have the same transmitted to other providers/controllers (cf. also Art. 20 GDPR);",
    "privacy-sec2list5":
      "file complaints with the supervisory authority if they believe that data concerning them is being processed by the controller in breach of data protection provisions (see also Art. 77 GDPR).",
    "privacy-sec2text1":
      "In addition, the controller is obliged to inform all recipients to whom it discloses data of any such corrections, deletions, or restrictions placed on processing the same per Art. 16, 17 Para. 1, 18 GDPR. However, this obligation does not apply if such notification is impossible or involves a disproportionate effort. Nevertheless, users have a right to information about these recipients. Likewise, under Art. 21 GDPR, users and data subjects have the right to object to the controller's future processing of their data pursuant to Art. 6 Para. 1 lit. f) GDPR. In particular, an objection to data processing for the purpose of direct advertising is permissible.",
    "privacy-sec3Heading": "Information about the data processing",
    "privacy-sec3text1":
      "If you contact us via email or the contact form, the data you provide will be used for the purpose of processing your request. We must have this data in order to process and answer your inquiry; otherwise we will not be able to answer it in full or at all. The legal basis for this data processing is Art. 6 Para. 1 lit. b) GDPR. Your data will be deleted once we have fully answered your inquiry and there is no further legal obligation to store your data, such as if an order or contract resulted therefrom.",
    "privacy-sec3SocialHeading": "Social media links via graphics",
    "privacy-sec3SocialText1":
      " We also integrate the following social media sites into our website. The integration takes place via a linked graphic of the respective site. The use of these graphics stored on our own servers prevents the automatic connection to the servers of these networks for their display. Only by clicking on the corresponding graphic will you be forwarded to the service of the respective social network. Once you click, that network may record information about you and your visit to our site. It cannot be ruled out that such data will be processed in the United States.",
    "privacy-sec3SocialText2":
      "Initially, this data includes such things as your IP address, the date and time of your visit, and the page visited. If you are logged into your user account on that network, however, the network operator might assign the information collected about your visit to our site to your personal account. If you interact by clicking Like, Share, etc., this information can be stored your personal user account and possibly posted on the respective network. To prevent this, you need to log out of your social media account before clicking on the graphic. The various social media networks also offer settings that you can configure accordingly.",
    "privacy-sec3SocialSubHeading":
      "The following social networks are integrated into our site by linked graphics:",
    "privacy-sec3Twitter":
      "We maintain an online presence on Twitter to present our company and our services and to communicate with customers/prospects. Twitter is a service provided by Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. We would like to point out that this might cause user data to be processed outside the European Union, particularly in the United States. This may increase risks for users that, for example, may make subsequent access to the user data more difficult. We also do not have access to this user data. Access is only available to Twitter. Twitter Inc. is certified under the Privacy Shield and committed to adhering to European privacy standards.",
    "privacy-sec3TwitterPolicy":
      "The privacy policy of Twitter can be found at",
    "privacy-sec3Ig":
      "The data controller for your information is Facebook Ireland. You can contact this company either online or by mail to: Facebook Ireland Ltd. 4 Grand Canal Square Grand Canal Harbor Dublin 2 Ireland",
    "privacy-otherHeading": "Cookies",
    "privacy-googleHeading": "Google Analytics (optional)",
    "privacy-googleText":
      'Our website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). The information generated by the cookie about your use of our website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of our website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate your IP address with any other data held by Google. Further information about Google’s privacy policy may be obtained from',
    "privacy-enchargeText":
      "Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Website. Users are responsible for any third-party Personal Data obtained, published or shared through this Website and confirm that they have the third party's consent to provide the Data to the Owner",
    "thankyou-reports":
      "Thank you for your interest in our report. Next, we will send you an email with a confirmation link. If you have already subscribed in the past, this email will be skipped and you will receive a message with the download of the report directly in your mailbox.",
    "thankyou-webinars":
      "Thank you for your interest in our webinar. Happy to have you dive into our exciting events around the Serverless World. Next, we will send you an email with a confirmation link. If you have already subscribed in the past, this email will be skipped , and you will receive a message with further information about the webinar.",
    "thankyou-contact":
      "Thank you for your request. Next, we will send you an email with a confirmation link. If you have already been subscribed in the past, this email will be skipped. We will look at your request and get back to you via email as soon as possible to discuss the individual next steps.",
    "thankyou-casestudy":
      "Thank you for your request. Next, we will send you an email with a confirmation link. If you have already been subscribed in the past, this email will be skipped. We will look at your request and get back to you via email as soon as possible to discuss the individual next steps.",
    "thankyou-subscribe":
      "Thank you for your confirmation and your interest in our content. From now on you will receive exciting information and news from the serverless world directly in your email inbox.",
    "thankyou-newsletter":
      "Thank you for your interest in our amazing cloud newsletter .Next, we will send you an email with a confirmation link. If you have already been subscribed in the past, this email will be skipped.",
    "thankyou-backHome": "Back to home page",
    "thankyou-title": ".Thank you",
    "thankyou-solutions":
      "Thank you for your interest in our exciting cloud solutions. Next, we will send you an email with a confirmation link. If you have already subscribed in the past, this email will be skipped and you will receive a message to receive further information about the requested solution.",
    "case-studies-challenge": "Challenge",
    "case-studies-solution": "Solution",
    "case-studies-benefits": "Benefits",
    "case-studies-heading":
      "Take a deep dive into some of our unique success stories, and get a better vision into the innovative solutions we build to help organizations reach the next level.",
    "scoping-call-heading": ".Ready for the next innovative level?",
    "scoping-call-description":
      "Digitization and optimization that best fits your company's goals",
    "scoping-call-optimization-title": "Acceleration of digitalization",
    "scoping-call-optimization-description":
      "Our scoping call is used to identify and develop possible digitization projects and options for optimization in digitization processes that have already been implemented - how can you make your existing use cases more efficient or build new digital business models?",
    "scoping-call-who-are-we-title": "About us",
    "scoping-call-who-are-we-description":
      "Cloud specialists with passion and 100% focus on AWS - that's us at globaldatanet. We have been working on digitization concepts with cloud solutions since 2017 and have been recognized by AWS as an “Advanced Consulting Partner”. Thanks to our serverless-first approach, we can also tackle your individual requirements quickly and implement your project together in weeks instead of months. One of the myriad benefits of using managed AWS services is the pay-as-you-go approach. Means: Only the usage that is actually used by you is charged for the services. This means that there are no high costs for resources that are currently not being used at all. We focus on the business and how your company can be optimized - globaldatanet takes you to the next level of digitization.",
    "scoping-call-procedure-title": "Your new digital strategy",
    "scoping-call-heading1": "State of affairs / maturity",
    "scoping-call-description1":
      "What is the current status of your applications with regard to digitized and cloud-based solutions?",
    "scoping-call-heading2": "Individual challenges",
    "scoping-call-description2":
      "What challenges do you face that make optimized and maximally efficient work impossible at the moment?",
    "scoping-call-heading3": "Priorities / prioritization",
    "scoping-call-description3":
      "Which fields of action result from the identified challenges?",
    "scoping-call-heading4": "Solutions",
    "scoping-call-description4":
      "Which solution is available for your individual use cases?",
    "scoping-call-heading5": "Guideline / Roadmap / Next Steps",
    "scoping-call-description5":
      "What are the next steps that you can implement right away? What are the next steps that we will take together on your way towards digitizing your company?",
    "scoping-call-contact-heading": "Let's discuss your next step",
    "opensource-heading": ".Open Source",
    "opensource-heading-description":
      "We are proud to share open source projects with the community. We believe that open source is good for everyone and we are committed to bringing the value of open source to our customers.",
    "opensource-projectsHeading": "Open Source Projects",
    "opensource-action-button": "Explore our Projects",
    "opensource-project-action-button": "Explore project",
    "opensource-projectheader1": "AWS Firewall Factory",
    "opensource-projectdesc1":
      "In simple scenarios and for smaller applications, its is very easy to implement WAFs on an individual basis. However, in larger environments with tens or even hundreds of applications, it is advisable to aim for central governance and automation. This simple solution helps you deploy, update, and stage your Web Application Firewalls while managing them centrally via AWS Firewall Manager.",
    "opensource-projectheader2": " Cost Reporter",
    "opensource-projectdesc2":
      "A lambda which sends a daily cost and trend report to slack. It helps with one of the three FinOps phases: Inform. It can send you a report every day / only when cost increases / only when cost breached a threshold depending on your configuration. ",
    "opensource-projectheader3": " ECS Scheduler",
    "opensource-projectdesc3":
      "Shuts down and starts containers for cost saving in development environments. ECS Services with or without autoscaling configured can be shutdown using this lambda. Per default they are started at 7:00 AM and stopped at 9:00 PM, which should result in ~40% of cost saving for ECS in dev.",
    "opensource-projectheader4": "WAF Notification",
    "opensource-projectdesc4":
      "AWS WAF now provides early notifications of upcoming rule updates to your managed rule groups through Amazon Simple Notification Service (Amazon SNS). This automation will send slack notifactions of managed rule updates for a specific managed rule group.",
    "opensource-projectheader5": "AWS Marketplace Automation",
    "opensource-projectdesc5":
      "AWS Marketplace Automation - The solution will take care of new accounts added, existing ones which are SUSPENDED or when the specific tag for licences is updated in one account - that every existing licence will be shared or removed from that account.",
    "opensource-projectheader6": "AWS ORGTOOL",
    "opensource-projectdesc6":
      "This Tool is to export your AWS Organizations structure, Policies to Json file and import structure, SCPs to another AWS Organization. During import and export a a log file is written so that the processes can be traced",
    "opensource-description":
      "We at globaldatanet strongly believe in the benefit of OpenSource. It’s is a place where people with a passion for innovation and a thrive for improvement share and contribute their knowledge and build amazing tools and solutions together for the common good of everyone. The entire community of developers and consumers benefit from a variety of shared goals. Therefore our team also committed to make contribution. Especially in our fields of expertise we think we can make a good impact to provide useful tools and solutions, which will make everyones daily life much easier and efficient.",
    "opensource-description-header": "Contribution",
    "sustainability-heading": ".Sustainability",
    "sustainability-action-button": "Eden Reforestation",
    "sustainability-green-it-header": "Green IT",
    "sustainability-green-it-description":
      "The term “Green IT” summarizes the combination of digitization and sustainability. In the future, the energy for cloud services will come entirely from renewable energy sources, the cooling of the data centers should be as natural as possible and consume less and less energy in the future. Nowadays, solutions are also planned and designed with sustainability in mind. Serverless architectures promise a highly efficient and demand-oriented use of IT infrastructure, which is only billed according to actual consumption.",
    "sustainability-eden-header": "Eden Reforestation",
    "sustainability-eden-description":
      "Eden operates in nine project nations, plant nearly a million trees per day, and maintain planting levels even when faced with civic unrest, extreme weather, wild animals, or other disruptions. As they restore the forest canopy, leaf litter, water, and wildlife return, and nature begins to heal. Meanwhile, communities that are facing extreme poverty are given new opportunities at economic self-sufficiency, improved health, and education.",
    "sustainability-description-header": "1000 Trees Every Month",
    "sustainability-description":
      "In order to make a contribution to making the world a little greener and better, we have decided to work with Eden Reforestation Projetcs, one of the world’s best reforestation partners and from now on we will plant 1000 new trees every single months.",
    "sustainability-heading-description":
      "Sustainability is not just a word for us at globaldatanet - we live it. We love to develop sustainable, efficient and Serverless architectures that use energy and resources only when they are really needed.",
    "community-heading": ".Community",
    "community-heading-description":
      "Learning from each other and growing together",
    "community-description-header": "Engagement",
    "community-description":
      "Engaging in the community is an awesome way to share ideas and knowledge, get in touch with people with the same interest and exchange exciting thoughts about new topics. Especially the possibility to exchange with like-minded people, get new perspectives and learn about new things, is the reason why we feel so comfortable within the community and we think it’s important to give back to the community by contributing content and sharing thoughts in the same way.",
    "community-communitiesHeading": "Communities",
    "community-serverless-summit":
      "The Serverless Summit is the world’s largest Serverless conference. It’s an annual and global event, founded by globaldatanet. A place where serverless experts from all over the world, will be on our stage, share their insights and present the latest trends and innovations. The event is characterised by the very high quality of the sessions, the interaction with the community and the variety of innovative formats. In addition to tech & use case talks, panel discussions, workshops and serverless experts, available for questions in the background, we also founded the serverless awards, the world’s only format where the best solutions will be awarded by the community and industrial leaders. Our goal is to create value, build unique experiences and Learn & Connect.",
    "community-hug":
      "HashiCorp User Groups (HUG) are the cornerstones of the community: regular meetings hosted by locals, volunteer organizers who are passionate about HashiCorp tools and sharing knowledge and experiences. We are the founders and organizers of HUG in Hamburg - a quarterly meeting full of interesting conversations and insights all about HashiCorp, a place to share knowledge, exchange thoughts and network with like-minded people.",
    "community-awscommunityday":
      "AWS Community Day events are community-led conferences where event logistics and content is planned, sourced, and delivered by community leaders. Community Day events deliver a peer-to-peer learning experience, providing developers with a venue for them to acquire AWS knowledge in their preferred way: from one another.",
    "community-cdkday":
      "The CDK Day is a yearly community event about AWS CDK, cdktf, projen and cdk8s. Different speakers from diverse backgrounds representing the whole spectrum of experience, from  'my first experience using cdk' up to 'my experience as a top 10 core AWS CDK contributor' - everyone is welcome. The focus in the sessions are Demos! More code and less slides. ",
    "community-awsusergroups":
      "AWS-focused user groups are a world-wide community of developers and executives who are united by their interest to share experiences, learn about new services and best practices, build relationships, and even make friend. Our team is active member of the AWS communities in Hamburg, Berlin and Belgrade and contributes with talks, panel discussions or even event sponsoring.",
    "community-button": "Visit",
    "security-packages-heading": "Cloud Security Fundamentals",
    "security-packages-button": "Book now",
    "security-packages-description": "One Day Workshop - Level 200",
    "security-packages-offer-heading": "Content",
    "security-packages-offer-description":
      "In this workshop you will create a simple cloud service and select appropriate security measures to protect it using AWS Services, such as AWS Identity and Access Management (IAM), AWS Config, Amazon GuardDuty, AWS CloudTrail. The goal is to provide Cloud Engineers with practical knowledge on how to investigate security issues and build a security Baseline on AWS. The workshop is built on the foundation of AWS Security Reference Architecture Examples and presents engineers and cloud competence center teams with a range of best practice patterns on how to secure applications in AWS.",
    "header-securitypackage-fundamentals":
      "Cloud Security Fundamentals Workshop",
    "security-keytopicsHeading1": "Advice",
    "security-keytopicsDescription1":
      "Guidance for Cloud Competence Center & Cloud Engineers",
    "security-keytopicsHeading2": "Security Baseline",
    "security-keytopicsDescription2":
      "You will learn about Best Practices Patterns for Security & Data isolation using account separation",
    "security-keytopicsHeading3": "Hands-On Workshop",
    "security-keytopicsDescription3":
      "You will get a lot of real use case examples and get your hands dirty in an Hands-On Workshop.",
    "security-keytopicsHeading4": "Q&A Session",
    "security-keytopicsDescription4":
      "Get your questions answered by an expert.",
    "security-packages-buy-price-head": "Ready?",
    "security-packages-buy-price-info":
      "Schedule your workshop now, get to know one of our experts and take your team to the next level.",
    "security-packages-buy-price-info-details":
      "per workshop, up to 3 participants - for more we offer you an individual price on request",
    "header-securitypackage-serverless-saas": "Serverless SaaS Workshop",
    "serverless-saas-packages-heading": "Serverless SaaS Workshop",
    "serverless-saas-packages-description": "One Day Workshop - Level 200",
    "serverless-saas-packages-offer-heading": "Content",
    "serverless-saas-packages-offer-description":
      "In this workshop you will be building a multi-tenant Software-as-a-Service (SaaS) solution using AWS Serverless Services, such as Amazon API Gateway, Amazon Cognito, AWS Lambda, Amazon DynamoDB, AWS CodePipeline, and Amazon CloudWatch. The goal is to provide SaaS developers and architects with working code that illustrates how to design and deliver a multi-tenant SaaS solution on AWS. The workshop is built on the foundation of a Serverless SaaS Reference Architecture and presents architects and developers with a range of new multi-tenant design considerations.",
    "serverless-saas-keytopicsHeading1": "Serverless Web Application",
    "serverless-saas-keytopicsDescription1":
      "Introduction of all components....",
    "awards-description":
      "Through our work in the community, we have received several awards from Amazon Web Services and Hashicorp. These awards are given to mentors, technical enthusiasts and emerging thought leaders who are passionate about sharing their knowledge and networking with the technical community.",
    "awards-header": "Community Leads",
    "community-openit":
      "Open IT is the largest Serbian IT conference for students. 300 students and 20 companies meet over two days. With our support, we try to bring the IT scene in Serbia one step further! OpenIT was founded with the idea of ​​helping young IT students to take their next step, to develop their potential, to give them the opportunity to discover their passion in different areas of IT, to learn about the latest technologies and to network with people and companies.",
    "podcast-description":
      "A unique mix of cloud native and cloud curiosity. Listen to experts, talk about modern challenges and opportunities within the cloud and its various potentials for businesses of every size.",
    "podcast-why-title": "Podcast meets passion",
    "podcast-why-description": `We are passionate innovators. But sometimes, we also enjoy to listen to podcasts. So we decided to combine that and present to you the Serverless World Podcast.
Take a break or listen to it in the background, no matter when and where. It’s up to you.`,

    "first-podcast-title": "001 - Digitization in logistics",
    "first-podcast-description":
      "In this episode our CEO Marc Schroeter will discuss with Janek Lünstedt a Sr. Enterprise Account Manager at Amazon Web Services about digitalisation challenges and chances in the logistic industry.",
    "first-podcast-language": "German",
    "first-podcast-attendees":
      "Marc Schröter (globaldatanet), Janek Luenstedt (Amazon Web Services)",

    "second-podcast-title": "002 - The ear to the net - Episode 10",
    "second-podcast-description":
      "In this episode, we discuss private cloud, public cloud and multi-cloud. What are the advantages and disadvantages? For which companies are private cloud offerings worthwhile? This episode is in German.",
    "second-podcast-language": "German",
    "second-podcast-attendees":
      "Marc Schröter (globaldatanet), Felix Höger (EuroCloud), Silke Kanes (Scopevisio AG), Henrik Hasenkamp (gridscale)",
    "third-podcast-title": "003 - Intelligent document processing with AI",
    "third-podcast-description":
      "Together with Larissa Becka, AWS Specialist for Machine Learning and AI, our CEO Marc Schröter talks about the opportunities and common use cases of IDP.",
    "third-podcast-language": "German",
    "third-podcast-attendees":
      "Marc Schröter (globaldatanet), Larissa Becka (Amazon Web Services)",
    "podcast-subscribe": "Subscribe",
    "view-podcast": "View podcast on ",
    "solutions-read-more": "read more",
    "read-more": "read more",
    "solutions-page-subtitle":
      "Learn more about our cloud-native solutions, expand your knowledge through our exciting workshops, or take advantage of one of our excellent SaaS solutions that will help you and your business move forward.",
    "download-now": "Download Now",
    "download-now-report": "Download Report Now",
    "report-testimonial": "Testimonial",
    "report-details": "Details",
    "report-use-case": "Discover exciting use cases and solutions",
    "report-key-topics": "Key Topics",
    "cookie-text-one": "Your privacy is important to us!",
    "cookie-text-two":
      "We use cookies on our website. Some of them are essential,while others help us to improve our online offer.",
    "cookie-text-three": "You can find more information in our ",
    "cookie-text-four": "Privacy policy",
    "cookie-btn": "I accept",
    "cookie-btn-decline": "I decline",
    "report-main-description":
      "Download exciting cloud reports with the latest market analysis and trends, full of insights about how cloud services and technologies will accelerate your business and lead to innovation.",
    "report-download-btn": "Get Report Now",
    "report-first-name": "First name*",
    "report-last-name": "Last name*",
    "report-email": "Business Email*",
    "register-to-download-report": "Register to Download the report",
    "search-header": "search",
    "search-title": "Search",
    "search-description":
      "Explore the latest insights and success stories on our page by browsing through our engaging blog posts, in-depth case studies, cutting edge solutions, and comprehensive reports.",
    "search-button": "Start your discovery ...",
    "saas-solution-header": "SaaS Application Development",
    "saas-solution-subtitel":
      "The next generation of SaaS applications, well-architected and built by globaldatanet",
    "saas-solution-intro-header": "SaaS built in the cloud",
    "saas-solution-intro-description":
      "SaaS providers are under immense pressure to innovate and compete. They need to be able to respond quickly to the changing market and constantly deliver new functionality. Customers expect seamless onboarding, high service availability with no downtime, user-, volume-, and feature-based pricing, consistent high speed, and the highest levels of security and data isolation.",
    "saas-solution-intro-description2":
      " We help you build SaaS architectures surrounded with an operational and customer experience, that realizes the agility and software as a service tenets that are core to succeeding as a SaaS offering. By leveraging the latest AWS and SaaS concepts, design principles, common patterns, strategies, and best practices critical to designing, building, and managing multi-tenant SaaS applications on AWS, we provide the expertise you need for every phase of your SaaS business.",
    "saas-solution-details-header": "Your SaaS Accelerator",
    "saas-solution-details-01-header": "Cloud-Native SaaS Development",
    "saas-solution-details-01-description":
      "Development of decoupled microservices for your SaaS application plane, leveraging the benefits of cloud computing, serverless and container technology.",
    "saas-solution-details-02-header": "SaaS Cloud Architecture",
    "saas-solution-details-02-description":
      "Modern, dynamically scaling, highly available, event-driven, serverless architecture, paid-per-use with flexible tier-based infrastructure for pooled, hybrid and siloed microservices.",
    "saas-solution-details-03-header": "SaaS Modernization",
    "saas-solution-details-03-description":
      "Revitalize your SaaS applications by using a modernization program that creates a modern, agile application architecture. Starts with the business need in mind, and then focuses on technologies.",
    "saas-solution-details-04-header": "SaaS Optimization",
    "saas-solution-details-04-description":
      "Implementation of several optimizations to improve the scalability, costs, performance, agility, operational efficiency of your SaaS application and security of your tenants.",
    "saas-solution-details-05-header": "SaaS Transformation",
    "saas-solution-details-05-description":
      "Get your application running in a SaaS model. Implementation of shared services for DevOps and deployments, management and monitoring, onboarding, identity, billing and metering, metrics and analytics.",
    "saas-solution-process-header": "Process",
    "saas-solution-process-button": "Jetzt starten",
    "saas-solution-process-01-header": "Intro call",
    "saas-solution-process-01-description":
      "Introduction of globaldatanet, discuss your SaaS application and specific requirements, explore how our SaaS expertise and solutions can help you to achieve your goals.",
    "saas-solution-process-02-header": "Discovery call",
    "saas-solution-process-02-description":
      "Exploring your goals and exact needs, discuss challenges and draft first approaches to solutions.",
    "saas-solution-process-03-header": "Strategy workshop",
    "saas-solution-process-03-description":
      "Interactive session to explore and identify potential solutions that merged after the discovery call.",
    "saas-solution-process-04-header": "Story Mapping",
    "saas-solution-process-04-description":
      "Building a visual backlog based on user goals and journeys with user stories to each step. Planning of the first MVP release.",
    "saas-solution-process-05-header": "Proof of Concept (POC)",
    "saas-solution-process-05-description":
      "Using low/no-code tools, we develop a first POC version with a small scope in order to do a Validation of use case.",
    "saas-solution-process-06-header": "SaaS Foundation",
    "saas-solution-process-06-description":
      "A SaaS control plane is foundational to any multi-tenant SaaS model. We will implement all the functionality and services that are used to onboard, authenticate, manage, operate, and analyze a multi-tenant environment. Your team can focus on application features.",
    "saas-solution-process-07-header": "Minimum Viable Product (MVP)",
    "saas-solution-process-07-description":
      "Development of a version of your SaaS app that contains only the essential features to validate your customer needs by feedback.",
    "saas-solution-process-08-header": "Innovate",
    "saas-solution-process-08-description":
      "Stay ahead of the curve. Frequent feature releases and rapid market response.  Adding data-driven and AI-based capabilities.",
    "saas-review-header": "Free SaaS review",
    "saas-review-description":
      "The multi-tenant nature of SaaS applications requires architects to reconsider how they address the security, operational efficiency, stability, and agility of their SaaS environment. Schedule a free SaaS Review session with our SaaS specialists to get a full overview of your SaaS readiness.",
    "saas-review-header-01": "Latest SaaS concepts",
    "saas-review-description-01":
      "Get a valuable analysis of your current SaaS state. With the use of latest SaaS design principles, common patterns and strategies we identify areas for improvements.",
    "saas-review-header-02": "Best practices",
    "saas-review-description-02":
      "By leveraging well-architected SaaS best practices, we make sure your SaaS application operates secure, reliable, efficient, cost-effective and sustainable.",
    "saas-review-header-03": "Improvement plan",
    "saas-review-description-03":
      "Creation of an action plan and roadmap with all findings how to optimize your SaaS application and business.",
    "saas-review-button": "Book now",
    "saas-form-header": "Discover your SaaS application requirements",
    "rewind-aws-title": "The latest news from re:Invent 2023",
    "rewind-aws-description":
      "If you are using AWS to advance your business, but you are not aware of the latest announcements from the re:Invent 2023, here is your chance to catch up! 🚀 \n\n  This session includes Amazons generative AI assistant for business & developers and a range of innovations such as new database capabilities, developer tooling, CloudOps, security and many more. 🔥",
    "rewind-ctrace-title": "Automated ML Pipelines for Waste Management",
    "rewind-ctrace-description":
      "This use-case might be relevant for every company integrating ML into their operations on AWS!\n\n Mohammad Moallemi, our AWS Cloud & MLOps Engineer & Tobias Richard, ML Engineer/Data Scientist show how building a strong foundation can reduce operational costs and can be a door-opener for future ML use cases. ✨\n\nc-trace GmbH is a key player in the waste management logistics industry known for setting the standard with its innovative IT & logistics solutions. ♻️\n\n As their Machine Learning operations expanded, the challenge of configuring model trainings manually and maintaining training infrastructure was time consuming and hindering the team to focus on more relevant tasks that drive greater business value. ❌\n\n We built the foundation of their Serverless MLOps platform, offering self-service training pipelines with all the necessary requirements baked into it. From using versioned datasets to automated model versioning! \n\n All in all leading to more effective resource allocation and a new cloud-native landscape, that makes introducing new use cases and ideas easier. ✅",
    "rewind-2024-trend-title": "2024 Trends and the Future of Tech",
    "rewind-2024-trend-description":
      "We kicked off the re:Wind with an inspiring keynote speech about tech trends for 2024 and the overall future of tech! 🔮 \n\n At the start of each new year, we recap the previous one and its highlights. For instance, we reviewed the current state of cloud technology adoption, evolving roles, and skill requirements due to AI advancements, and overall industry trends impacting the workforce. 🚀  \n\n But we're not just looking back. Our goal is to focus on what lies ahead. Based on the advancements of 2023, we can only expect 2024 to be an even more impactful year, both from technological and business perspectives.But we're not just looking back. Our goal is to focus on what lies ahead. Based on the advancements of 2023, we can only expect 2024 to be an even more impactful year, both from technological and business perspectives.   ",
    "rewind-electric-charging-infrastructure-title":
      "Data platform for Germany's electric charging infrastructure",
    "rewind-electric-charging-infrastructure-description":
      "This session is about our impressive collaboration to support the expansion of e-charging infrastructure in Germany, featuring the Nationale Leitstelle Ladeinfrastruktur under the umbrella of NOW GmbH. 💜 \n\n The Leitstelle is now able to generate all required reportings needed to manage the ongoing Deutschlandnetz-Initiative in a simple & automated way, while being compliant to the strict guidelines of a public institution within the Federal Ministry for Digital & Transport Affairs. ✅\n\n This solution showcases how modern cloud technologies are essential in digitizing public institutions' processes. ✨",
    "rewind-ai-powered-sales-title":
      "AI powered Sales Pipeline for ISV Private Equity",
    "rewind-ai-powered-sales-description":
      "Finding hidden gems in the software industry and turning them into European market leaders is BID Equity's specialty. They seek out interesting, attractive, independently developed companies to acquire and improve. A unique challenge is that these companies are not actively sold; instead, they must be approached for acquisition but lack significant public data. 🔍💎 \n\n This is where we step in and highlight an AI-driven solution that crawls through the web, classifies potential software companies reliably & analyzes the company's industry sectors and technology stack using Natural Language Processing (NLP). 📈🌐 \n\n We also include how Machine Learning development, including building, training, and deploying models, can be made scalable through the use of SageMaker Studio & therefore cutting costs with an auto-scaling feature that boosts ML model hosting. ✅",
    "rewind-iot-data-platform-title":
      "Building the IoT & Data Platform for one of the largest fleets in the world",
    "rewind-iot-data-platform-description":
      "This pioneering cloud use case helps Hapag-Lloyd AG in operating the smartest container fleet in the world. 💯 \n\n These containers provide valuable information via IoT tracking devices, such as location, timing, temperature and health conditions, generated by different solution providers. This results in huge amounts of data that multiple technical teams, from Big Data to Smart Container, need to collect and harmonize before it turns into valuable insights. 📈 \n\n We solved that problem by developing a high-performance cloud-platform that ingests these massive amounts of data seamlessly in a cost-effective way by scaling quickly based on incoming and outgoing data. The data is processed, sent to various data streams, and converted into valuable insights in near-real time. 🚀",
    "rewind-ccoe-title":
      "Building a Cloud Center of Excellence (CCoE) in a regulated industry",
    "rewind-ccoe-description":
      "We share exclusive insights and valuable information on how to navigate the regulatory industry and why working with public sector institutions means aligning people with business. \n\n BARMER, one of the largest health insurance companies in Germany, develops innovative care offerings and provides rapid access to innovations in medicine, prevention, rehabilitation, and care. Since 2020, we have been working closely with their IT team to provide a secure cloud platform for service development and operations. \n\n What makes this cloud use-case even more special is the health industry's strictly regulated guidelines, all of which needed consideration, including endpoint monitoring, while maintaining project agility. \n\n We established a secure, multi-account AWS environment based on security best practices and compliance with standards such as the General Data Protection Regulation (GDPR). As one of the 3 AWS Security Competency Partners in the DACH region, we hope to inspire the public sector on AWS with this session! 🔥",
    "rewind-title": "re:Wind 2024",
    "rewind-subtitle":
      "5 Cloud Stories that display our customers unique challenges & how we solved them to achieve their business goals. Additionally we dived into recent tech trends and covered the most relevant news from the re:Invent 2023.",
    "rewind-button": "Watch now",
  },
};
