import React, { createContext, useState, useEffect, FC } from "react";
import { I18nProvider, LOCALES } from "../i18n";

export const LanguageContext = createContext(undefined);

type Props = {
  children: any;
};

export const LanguageContextProvider: FC<Props> = ({ children }) => {
  const [language, setLanguage] = useState("");
  const [locale, setLocale] = useState(LOCALES.EN);

  useEffect(() => {
    setLanguage(localStorage.getItem("lang"));
  }, []);

  const onChangeLanguege = (lang: string) => {
    setLanguage(lang);
  };

  useEffect(() => {
    if (!localStorage.getItem("lang")) {
      if (navigator.language === "de_DE") {
        setLanguage("DE");
      }
      setLanguage("EN");
    }
  }, []);

  useEffect(() => {
    if (language === "DE") {
      setLocale(LOCALES.DE);
    } else {
      setLocale(LOCALES.EN);
    }
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, onChangeLanguege }}>
      <I18nProvider locale={locale}>{children}</I18nProvider>
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
