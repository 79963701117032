import { Link } from "gatsby";
import React, { FC } from "react";

type BlogModalProps = {
  blogWrapperClass: string;
  blogTagClass: string;
  basePathForUrl?: string;
  tagsFromBlog: any;
};

const BlogModalContent: FC<BlogModalProps> = ({
  blogWrapperClass,
  tagsFromBlog,
  blogTagClass,
  basePathForUrl,
}) => {
  return (
    <div className={blogWrapperClass}>
      {tagsFromBlog &&
        tagsFromBlog.map(({ title }: any, index: number) => (
          <div key={index} className={blogTagClass}>
            <Link
              activeStyle={{
                color: "#00ECBD",
                borderBottom: "1px solid #00ECBD",
              }}
              to={`${
                basePathForUrl !== undefined
                  ? `${basePathForUrl}/${title.toLowerCase()}`
                  : `/${title}`
              }`}
            >
              {title}
            </Link>
          </div>
        ))}
      <div className={blogTagClass}>
        <Link
          activeStyle={{
            color: "#00ECBD",
            borderBottom: "1px solid #00ECBD",
          }}
          style={{ alignSelf: "center" }}
          to={basePathForUrl !== undefined ? basePathForUrl : "/case-study/"}
        >
          All
        </Link>
      </div>
    </div>
  );
};

export default BlogModalContent;
