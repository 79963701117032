import React, { useState } from "react";
import * as styles from "./FooterShort.module.scss";
import FooterItem from "./FooterItem/FooterItem";
import {
  NavigationItems,
  OurCompanyItems,
  SolutionsItems,
} from "./Footer.helper";
import { Link } from "gatsby";

const FooterTab = () => {
  const [solution, setsolution] = useState<boolean>(true);
  const [content, setcontent] = useState<boolean>(false);
  const [company, setcompany] = useState<boolean>(false);

  const soltab = () => {
    setsolution(true);
    setcontent(false);
    setcompany(false);
  };

  const contab = () => {
    setsolution(false);
    setcontent(true);
    setcompany(false);
  };

  const comtab = () => {
    setsolution(false);
    setcontent(false);
    setcompany(true);
  };

  return (
    <>
      <div className={styles.footerItemContainer}>
        <h4 className="heading">
          <span>.</span>
          Navigation
        </h4>
        <ul>
          <li>
            <Link to={`/`}>home</Link>
          </li>
          <li onClick={() => soltab()}>solutions</li>
          <li onClick={() => contab()}>content hub</li>
          <li onClick={() => comtab()}>company</li>
          <li>
            <Link to={`/contact`}>contact</Link>
          </li>
        </ul>
      </div>
      {solution === true ? (
        <FooterItem
          title={"Solutions"}
          navigationTitles={SolutionsItems}
          classNameOfFooterItem={styles.footerItemContainer}
        />
      ) : null}
      {content === true ? (
        <FooterItem
          title={"Content hub"}
          navigationTitles={NavigationItems}
          classNameOfFooterItem={styles.footerItemContainer}
        />
      ) : null}
      {company === true ? (
        <FooterItem
          title={"Company"}
          navigationTitles={OurCompanyItems}
          classNameOfFooterItem={styles.footerItemContainer}
        />
      ) : null}
    </>
  );
};

export default FooterTab;
