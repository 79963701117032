import React, { FC } from "react";
import { motion } from "framer-motion";
import { CircleAnimateProps } from "./CircleHeplers";

type Props = {
  circleStyleClass: string;
};

const CircleBlur: FC<Props & CircleAnimateProps> = ({
  initialOpacity,
  initialScale,
  animateScale,
  animateOpacity,
  transitionType,
  transitionDuration,
  transitionDelay,
  circleStyleClass,
}) => {
  return (
    <motion.div
      initial={{ opacity: initialOpacity, scale: initialScale }}
      animate={{ opacity: animateOpacity, scale: animateScale }}
      transition={{
        ease: transitionType,
        duration: transitionDuration,
        delay: transitionDelay,
      }}
      className={circleStyleClass}
    ></motion.div>
  );
};

export default CircleBlur;
