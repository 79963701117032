import React, { Fragment, FC } from "react";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./locales";
import messages from "./messages";

type Props = {
  children: any;
  locale: any;
};

const Provider: FC<Props> = ({ children, locale = LOCALES.EN }) => (
  <IntlProvider
    locale={locale}
    textComponent={Fragment}
    messages={messages[locale]}
  >
    {children}
  </IntlProvider>
);

export default Provider;
