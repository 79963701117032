import React, { FC, useEffect, useState } from "react";
import * as styles from "./MobileModal.module.scss";
import globaldatanetlogo from "../../../images/globaldatanetlogo.svg";
import DefaultModalContent from "./DefaultModalContent";
import BlogModalContent from "./BlogModalContent";
import { Link } from "gatsby";

export type ModalProps = {
  isModalHidden?: boolean;
  modalContent?: any;
  setIsModalHidden?: (x: boolean) => void;
  blogWrapperClass?: string;
  blogTagClass?: string;
  basePathForUrl?: string;
  tagsFromBlog?: any;
};

const MobileModal: FC<ModalProps> = ({
  isModalHidden = false,
  setIsModalHidden,
  modalContent,
  blogWrapperClass,
  blogTagClass,
  basePathForUrl,
  tagsFromBlog,
}) => {
  const [showSolutions, setShowSolutions] = useState<boolean>(false);
  const [showContentHub, setShowContentHub] = useState<boolean>(false);
  const [showCompany, setShowCompany] = useState<boolean>(false);
  const [showLanguage, setShowLanguage] = useState<boolean>(false);

  useEffect(() => {
    if (!isModalHidden) {
      setShowSolutions(false);
      setShowContentHub(false);
      setShowCompany(false);
    }
  }, [isModalHidden]);

  return (
    <div className={styles.modalWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100px",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Link to="/">
          <img
            src={globaldatanetlogo}
            width="150px"
            height="auto"
            alt="globaldatanet"
          />
        </Link>
        <span onClick={() => setIsModalHidden(false)}>close</span>
      </div>
      {modalContent !== "blog" ? (
        <DefaultModalContent
          setShowCompany={setShowCompany}
          setShowContentHub={setShowContentHub}
          setShowSolutions={setShowSolutions}
          setShowLanguage={setShowLanguage}
          showCompany={showCompany}
          showContentHub={showContentHub}
          showSolutions={showSolutions}
          showLanguage={showLanguage}
        />
      ) : (
        <BlogModalContent
          basePathForUrl={basePathForUrl}
          blogTagClass={blogTagClass}
          blogWrapperClass={blogWrapperClass}
          tagsFromBlog={tagsFromBlog}
        />
      )}
    </div>
  );
};

export default MobileModal;
