import { ReactElement } from "react";
import translate from "../../../i18n/translate";

export type LinksNav = {
  title: string | ReactElement;
  link: string;
};

export const solution: LinksNav[] = [
  { title: translate("header-developmentHeader"), link: "development" },
  { title: translate("header-optimizeHeader"), link: "optimize" },
  { title: translate("header-automateHeader"), link: "automate" },
  {
    title: "saas application development",
    link: "saas-application-development",
  },
];

export const contentHub: LinksNav[] = [
  { title: translate("header-webinars"), link: "webinars" },
  { title: translate("header-techBlog"), link: "tech-blog" },
  { title: translate("header-reports"), link: "reports" },
  { title: translate("header-caseStudies"), link: "case-study" },
  { title: translate("header-cloudInsights"), link: "cloud-insights" },
  { title: translate("header-opensource"), link: "opensource" },
  { title: translate("header-community"), link: "community" },
  { title: "podcast", link: "podcast" },
];

export const companyLinks = [
  { title: translate("header-partners"), link: "partners" },
  { title: translate("header-ourTeam"), link: "our-team" },
  { title: translate("header-ourStory"), link: "our-story" },
  { title: translate("header-news"), link: "news" },
  { title: translate("header-careers"), link: "careers" },
  { title: translate("header-sustainability"), link: "sustainability" },
];

export const languages = [
  { title: "EN", value: "EN" },
  { title: "DE", value: "DE" },
];
