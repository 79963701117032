import { Link } from "gatsby";
import React, { FC } from "react";

type Props = {
  title: string;
  navigationTitles: any;
  classNameOfFooterItem: string;
};

const FooterItem: FC<Props> = ({
  title,
  navigationTitles,
  classNameOfFooterItem,
}) => {
  return (
    <div className={classNameOfFooterItem}>
      <h4 className="heading">
        <span>.</span>
        {title}
      </h4>
      <ul>
        {navigationTitles.map((navItem: any, index: number) => (
          <li key={index}>
            <Link to={`/${navItem.link}`}>{navItem.title}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FooterItem;
