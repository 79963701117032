import React, { FC } from "react";
import * as styles from "./customButton.module.scss";
import { Link } from "gatsby";
import cx from "classnames";

type Props = {
  image: any;
  text: string | any;
  type?: any;
  linkPath?: any;
  externalLink?: any;
  buttonMainClass?: any;
  buttonClass?: any;
};

const CustomButton: FC<Props> = ({
  image,
  text,
  type,
  linkPath,
  externalLink,
  buttonMainClass,
  buttonClass,
}) => {
  return externalLink ? (
    <div className={buttonMainClass} style={{ display: "flex" }}>
      <a href={externalLink} target="_blank" rel="noreferrer">
        <button className={cx(buttonClass, styles.contactButton)} type={type}>
          {text} &nbsp;
          <img src={image} alt={text} />
        </button>
      </a>
    </div>
  ) : (
    <div className={buttonMainClass}>
      {text !== "Submit" ? (
        <Link to={linkPath}>
          <button className={cx(buttonClass, styles.contactButton)} type={type}>
            {text} &nbsp;
            <img src={image} alt={text} />
          </button>
        </Link>
      ) : (
        <button className={cx(buttonClass, styles.contactButton)} type={type}>
          {text} &nbsp;
          <img src={image} alt={text} />
        </button>
      )}
    </div>
  );
};

export default CustomButton;
