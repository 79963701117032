import React, { FC } from "react";
import { motion } from "framer-motion";
import { CircleAnimateProps } from "./CircleHeplers";
import blurImg from "../../../images/Ellipse.svg";
type Props = {
  circleStyleClass: string;
};

const CircleGreenBlur: FC<Props & CircleAnimateProps> = ({
  initialOpacity,
  initialScale,
  animateScale,
  animateOpacity,
  transitionType,
  transitionDuration,
  transitionDelay,
  circleStyleClass,
}) => {
  return (
    <motion.div
      initial={{ scale: initialScale }}
      animate={{ scale: animateScale }}
      transition={{
        ease: transitionType,
        duration: transitionDuration,
        delay: transitionDelay,
      }}
      className={circleStyleClass}
    >
      <img src={blurImg} alt="" width="600px" height="600px" />
    </motion.div>
  );
};

export default CircleGreenBlur;
