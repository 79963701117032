import { Link } from "gatsby";
import React, { FC, useState, useContext } from "react";
import * as styles from "./Header.module.scss";
import globaldatanetlogo from "../../../images/gdn-x1f.svg";
import smallArrowUp from "../../../images/smallArrowUp.svg";
import smallArrowDown from "../../../images/smallArrowDown.svg";
import lupe from "../../../images/lupe.png";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import {
  companyLinks,
  contentHub,
  LinksNav,
  solution,
  languages,
} from "./HeaderHelper";
import HeaderItem from "./HeaderItem";
import LanguageItem from "./LanguagesItem";
import translate from "../../../i18n/translate";
import { LanguageContext } from "../../../context/languageContext";

type Props = {
  isModalHidden?: boolean;
  setIsModalHidden?: (x: boolean) => void;
};

const Header: FC<Props> = ({ setIsModalHidden }) => {
  const [showSolutions, setShowSolutions] = useState<boolean>(false);
  const [showContentHub, setShowContentHub] = useState<boolean>(false);
  const [showCompany, setShowCompany] = useState<boolean>(false);
  const { onChangeLanguege, language } = useContext(LanguageContext);

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.header
      data-testid="header-test"
      initial="hidden"
      animate="visible"
      variants={variants}
      transition={{ ease: "easeOut", duration: 1.3 }}
      className={styles.headerContainer}
    >
      <Link className={styles.homeLink} to={"/"}>
        <img
          src={globaldatanetlogo}
          width="225px"
          height="auto"
          alt="globaldatanet"
        />
      </Link>
      <Helmet>
        <script type="text/javascript" src={"/encharge.js"} />
        <script type="text/javascript" src={"/google.js?id=GTM-KWHTT98"} />
      </Helmet>
      <nav>
        <ul>
          <li>
            <Link to={"/"}>home</Link>
          </li>
          <li
            onMouseEnter={() => setShowSolutions(true)}
            onMouseLeave={() => setShowSolutions(false)}
            style={{
              zIndex: showContentHub ? 99999999999999 : 99999999999999999999,
            }}
          >
            <span>
              services
              <img src={showSolutions ? smallArrowUp : smallArrowDown} alt="" />
            </span>
            <ul className="dropdown">
              {solution.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li>
            <Link to={"/solutions"}>solutions</Link>
          </li>
          <li
            onMouseEnter={() => setShowContentHub(true)}
            onMouseLeave={() => setShowContentHub(false)}
            style={{
              zIndex: showContentHub ? 99999999999999 : 99999999999999999999,
            }}
          >
            <span>
              {translate("header-contentHub")}
              <img
                src={showContentHub ? smallArrowUp : smallArrowDown}
                alt=""
              />
            </span>
            <ul className="dropdown" style={{ zIndex: 9999999999999999999 }}>
              {contentHub.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li
            onMouseEnter={() => setShowCompany(true)}
            onMouseLeave={() => setShowCompany(false)}
          >
            <span>
              {translate("header-company")}
              <img src={showCompany ? smallArrowUp : smallArrowDown} alt="" />
            </span>
            <ul className="dropdown">
              {companyLinks.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li>
            <Link
              to={"/contact"}
              className={styles.contactLink}
              activeStyle={{
                borderBottom: "1px solid #00ecbd",
                color: "#00ecbd",
              }}
            >
              {translate("header-contact")}
            </Link>
          </li>
          <li
            onMouseEnter={() => setShowCompany(true)}
            onMouseLeave={() => setShowCompany(false)}
          >
            <span style={{ cursor: "pointer" }}>
              {language}
              <img src={showCompany ? smallArrowUp : smallArrowDown} alt="" />
            </span>
            <ul className="dropdown">
              {languages.map((item: any) => (
                <LanguageItem
                  item={item}
                  key={item.title}
                  onSelectLanguage={lang => {
                    onChangeLanguege(lang);
                  }}
                />
              ))}
            </ul>
          </li>
          <li>
            <Link to={"/search"} className={styles.contactLink}>
              <img
                src={lupe}
                alt="Explore the latest insights and success stories on our page"
              />
            </Link>
          </li>
        </ul>
      </nav>

      <span
        className={styles.menuHeader}
        onClick={() => setIsModalHidden(true)}
      >
        menu
      </span>
    </motion.header>
  );
};

export default Header;
