// extracted by mini-css-extract-plugin
export var aboutMainCircleBlurBlue = "FooterShort-module--aboutMainCircleBlurBlue--MgMs3";
export var aboutMainCircleBlurGreen = "FooterShort-module--aboutMainCircleBlurGreen--F72fa";
export var backgroundCircles = "FooterShort-module--backgroundCircles--wbA6M";
export var buttonClasses = "FooterShort-module--buttonClasses--0vB+8";
export var circleGreenBlurDiv = "FooterShort-module--circleGreenBlurDiv--GQHIk";
export var contactButton = "FooterShort-module--contactButton--FKs0O";
export var contactOutlineCircle = "FooterShort-module--contactOutlineCircle--O-Jp1";
export var contentLogo = "FooterShort-module--contentLogo--txsW8";
export var footerBackgroundBlock = "FooterShort-module--footerBackgroundBlock--TAA3t";
export var footerBackgroundImage = "FooterShort-module--footerBackgroundImage--JxNa1";
export var footerContainer = "FooterShort-module--footerContainer--sDzLO";
export var footerContent = "FooterShort-module--footerContent--DuqI0";
export var footerContentBottomNavigation = "FooterShort-module--footerContentBottomNavigation--2YOIN";
export var footerContentBottomNavigationDescription = "FooterShort-module--footerContentBottomNavigationDescription--ptgum";
export var footerContentBottomNavigationLogo = "FooterShort-module--footerContentBottomNavigationLogo--YWB8V";
export var footerContentBottomNavigationSocial = "FooterShort-module--footerContentBottomNavigationSocial--LN0HA";
export var footerContentDescription = "FooterShort-module--footerContentDescription--gS9Ui";
export var footerContentSubscribeSection = "FooterShort-module--footerContentSubscribeSection--dxr2T";
export var footerContentTitle = "FooterShort-module--footerContentTitle--RK2kY";
export var footerImprintAndPolicy = "FooterShort-module--footerImprintAndPolicy--FwEjT";
export var footerInfo = "FooterShort-module--footerInfo--Umudv";
export var footerItemContainer = "FooterShort-module--footerItemContainer--gS9ek";
export var heroBackgroundInnerCircleGreen = "FooterShort-module--heroBackgroundInnerCircleGreen--9ig8i";
export var heroBackgroundInnerCircleOverflowText = "FooterShort-module--heroBackgroundInnerCircleOverflowText--xv9cX";