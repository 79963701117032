import React, { FC, ReactElement } from "react";
import { motion } from "framer-motion";
import { CircleAnimateProps } from "./CircleHeplers";

type Props = {
  children?: ReactElement;
  isBlurVisible?: boolean;
  circleStyleClass?: string;
};
const CircleOutline: FC<Props & CircleAnimateProps> = ({
  animateScale,
  initialScale,
  transitionType,
  transitionDuration,
  transitionDelay,
  children,
  isBlurVisible,
  circleStyleClass,
}) => {
  return (
    <motion.div
      initial={{ scale: initialScale }}
      animate={{
        scale: animateScale,
      }}
      transition={{
        ease: transitionType,
        duration: transitionDuration,
        delay: transitionDelay,
      }}
      className={circleStyleClass}
    >
      {isBlurVisible && children}
    </motion.div>
  );
};

export default CircleOutline;
