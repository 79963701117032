import translate from "../../../i18n/translate";

export const NavigationItems = [
  { title: translate("header-webinars"), link: "webinars" },
  { title: translate("header-techBlog"), link: "tech-blog" },
  { title: translate("header-reports"), link: "reports" },
  { title: translate("header-cloudInsights"), link: "cloud-insights" },
  { title: translate("header-caseStudies"), link: "case-study" },
];

export const SolutionsItems = [
  { title: translate("header-optimizeHeader"), link: "optimize" },
  { title: translate("header-developmentHeader"), link: "development" },
  { title: translate("header-automateHeader"), link: "automate" },
  {
    title: "saas application development",
    link: "saas-application-development",
  },
];

export const OurCompanyItems = [
  { title: translate("header-partners"), link: "partners" },
  { title: translate("header-ourTeam"), link: "our-team" },
  { title: translate("header-ourStory"), link: "our-story" },
  { title: translate("header-news"), link: "news" },
  { title: translate("header-careers"), link: "careers" },
];
