import React from "react";

import { useLocation } from "@reach/router";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import * as styles from "./CookieConsent.module.scss";
import translate from "../../../i18n/translate";
import { Link } from "gatsby";

function isBrowser() {
  return typeof window !== "undefined";
}

function getValue(key: any, defaultValue: any) {
  return isBrowser() && window.localStorage.getItem(key)
    ? JSON.parse(window.localStorage.getItem(key))
    : defaultValue;
}

function setValue(key: any, value: any) {
  window.localStorage.setItem(key, JSON.stringify(value));
}

function useStickyState(defaultValue: any, key: any) {
  const [value, setter] = React.useState(() => {
    return getValue(key, defaultValue);
  });

  React.useEffect(() => {
    setValue(key, value);
  }, [key, value]);

  return [value, setter];
}

const CookieConsent = () => {
  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    "consentCookieHidden"
  );

  const EnableAnalytics = () => {
    document.cookie = "gatsby-gdpr-google-analytics=true";
    setBannerHidden(true);
  };
  const onCancel = () => {
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && (
        <div className={styles.container}>
          <div style={{ marginBottom: "10px" }}>
            <b>{translate("cookie-text-one")}</b>
            <br /> <br />
            {translate("cookie-text-two")}
            <br />
            {translate("cookie-text-three")}{" "}
            <Link style={{ color: "#00ecbd" }} to="/privacy-policy">
              {translate("cookie-text-four")}{" "}
            </Link>
          </div>
          <div>
            <button className={styles.btnDecline} onClick={onCancel}>
              {translate("cookie-btn-decline")}
            </button>
            <button className={styles.btn} onClick={EnableAnalytics}>
              {translate("cookie-btn")}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookieConsent;
