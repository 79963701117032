import { Link } from "gatsby";
import React, { FC, useContext } from "react";
import smallArrowUp from "../../../images/smallArrowUp.svg";
import smallArrowDown from "../../../images/smallArrowDown.svg";
import * as styles from "./MobileModal.module.scss";
import lupe from "../../../images/lupe18.png";
import CircleOutline from "../CircleOutlineAndBlur/CircleOutline";
import {
  companyLinks,
  contentHub,
  LinksNav,
  solution,
  languages,
} from "../../Layout/Header/HeaderHelper";
import HeaderItem from "../../Layout/Header/HeaderItem";
import LanguageItem from "../../Layout/Header/LanguagesItem";
import { LanguageContext } from "../../../context/languageContext";
import translate from "../../../i18n/translate";

type Props = {
  setShowSolutions: (x: boolean) => void;
  showSolutions: boolean;

  setShowContentHub: (x: boolean) => void;
  showContentHub: boolean;

  setShowCompany: (x: boolean) => void;
  showCompany: boolean;

  setShowLanguage: (x: boolean) => void;
  showLanguage: boolean;
};

interface ContextLangFix {
  language: string;
  onChangeLanguege: (x: string) => void;
}

const DefaultModalContent: FC<Props> = ({
  setShowSolutions,
  showSolutions,
  setShowContentHub,
  showContentHub,
  setShowCompany,
  showCompany,
  setShowLanguage,
  showLanguage,
}) => {
  const { onChangeLanguege, language } =
    useContext<ContextLangFix>(LanguageContext);
  return (
    <div className={styles.modalBody}>
      <div className={styles.homeWrapper}>
        <div className={styles.modalHeader}>
          <Link to="/">
            <span>home</span>
          </Link>
        </div>
        <ul className={styles.modalBody}>
          <li>
            <span onClick={() => setShowSolutions(!showSolutions)}>
              services
              <img
                src={!showSolutions ? smallArrowDown : smallArrowUp}
                alt=""
              />
            </span>
            <ul
              className={styles.dropdown}
              style={{ display: showSolutions ? "flex" : "none" }}
            >
              {solution.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li>
            <Link
              to={`/solutions`}
              activeStyle={{
                borderBottom: "1px solid #00ecbd",
                color: "#00ecbd",
              }}
            >
              <span className="heading">solutions</span>
            </Link>
          </li>
          <li>
            <span onClick={() => setShowContentHub(!showContentHub)}>
              {translate("header-contentHub")}
              <img
                src={!showContentHub ? smallArrowDown : smallArrowUp}
                alt=""
              />
            </span>
            <ul
              className={styles.dropdown}
              style={{ display: showContentHub ? "flex" : "none" }}
            >
              {contentHub.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li>
            <span onClick={() => setShowCompany(!showCompany)}>
              {translate("header-company")}
              <img src={!showCompany ? smallArrowDown : smallArrowUp} alt="" />
            </span>
            <ul
              className={styles.dropdown}
              style={{ display: showCompany ? "flex" : "none" }}
            >
              {companyLinks.map((item: LinksNav) => (
                <HeaderItem linkItem={item} key={item.link} />
              ))}
            </ul>
          </li>
          <li>
            <span>
              <Link to="/contact">{translate("header-contact")}</Link>
            </span>
          </li>
          <li>
            <span onClick={() => setShowLanguage(!showLanguage)}>
              {language}
              <img src={!showLanguage ? smallArrowDown : smallArrowUp} alt="" />
            </span>
            <ul
              className={styles.dropdown}
              style={{ display: showLanguage ? "flex" : "none" }}
            >
              {languages.map((item: any) => (
                <LanguageItem
                  item={item}
                  key={item.title}
                  onSelectLanguage={lang => {
                    onChangeLanguege(lang);
                  }}
                />
              ))}
            </ul>
          </li>
          <li>
            <Link to={"/search"}>
              <br />
              <img
                src={lupe}
                alt="Explore the latest insights and success stories on our page"
              />
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.backgroundCircles}>
        <CircleOutline
          isBlurVisible={true}
          initialScale={1}
          animateScale={1}
          transitionType={"easeIn"}
          transitionDelay={1}
          transitionDuration={1}
          circleStyleClass={styles.heroBackgroundCircleLeft}
        />
      </div>
    </div>
  );
};

export default DefaultModalContent;
