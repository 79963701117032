import React, { FC } from "react";
import { Link } from "gatsby";
import { LinksNav } from "./HeaderHelper";

type Props = {
  linkItem: LinksNav;
};

const HeaderItem: FC<Props> = ({ linkItem }) => {
  return (
    <li>
      <Link
        to={`/${linkItem.link}`}
        activeStyle={{
          borderBottom: "1px solid #00ecbd",
          color: "#00ecbd",
        }}
      >
        <span className="heading">
          <span>.</span>
          {linkItem.title}
        </span>
      </Link>
    </li>
  );
};

export default HeaderItem;
