import { LOCALES } from "../locales";

export default {
  [LOCALES.DE]: {
    "home-intro": ".More business .Made in the Cloud",
    "home-description":
      "Innovation und Effizienz durch preisgekrönte Cloud-Lösungen",
    "home-whoweare": "Über uns",
    "home-contactus": "Kontakt",
    "home-4years": "6 Jahre",
    "home-yearsDescription":
      "globaldatanet ist ein führendes Cloud-natives Unternehmen mit dem Ziel, Anwendungen und Plattformen der nächsten Generation zu entwickeln und zu modernisieren. Seit mehr als 6 Jahren unterstützt globaldatanet andere Unternehmen bei der Einführung modernster Technologien, um bestehende Herausforderungen zu lösen und neue Geschäfte zu generieren.",
    "home-aboutAws":
      "Mehrfach ausgezeichneter AWS Advanced Consulting Partner mit vollständigem Fokus auf die Cloud Services von Amazon Web Services. Mit unserem erfahrenen und zertifizierten Cloud Spezialisten-Team sind wir seit Tag 1 ein starker Partner im Bereich Entwicklung und Modernisierung von cloud-nativen Anwendungen.",
    "home-ourServiceGeneral": ".Unser Leistungen",
    "home-ourService": "01. Unser Leistungen",
    "home-ourService2": "02. Unser Leistungen",
    "home-ourService3": "03. Unser Leistungen",
    "home-development": "Entwicklung",
    "home-optimize": "Optimierung",
    "home-automate": "Automatisierung",
    "home-developmentDescription":
      "Um in schnelllebigen, software-getriebenen Märkten erfolgreich zu sein, müssen Unternehmen die Art und Weise ändern, wie sie Anwendungen entwerfen, erstellen und nutzen. Cloud-native Anwendungsentwicklung ist ein Ansatz zum Erstellen, Ausführen und Verbessern von Applikationen, welcher auf vertrauten Techniken und Technologien für Cloud Computing basiert. Cloud-native App-Entwicklung umfasst typischerweise DevOps, agile Methodik, Microservices, verschiedene Cloud-Plattformen, Container und “serverless Delivery” – kurz gesagt, jede neue und moderne Methode der Anwendungsbereitstellung.",
    "home-optimizeDescription":
      "Cloud Computing erfordert eine neue Art des Denkens über Themen wie Architektur und Design. Wir verstehen, dass es eine Herausforderung sein kann, mit dem Tempo der ganzen AWS-Innovationen Schritt zu halten und es Expertenwissen und eine Reihe von “Best Practices” benötigt, um das Maximum aus Ihrer Architektur herauszuholen. Wir bewerten Ihre Cloud-Umgebung, berichten über kritische Sicherheitslücken, empfehlen Modifikationen und gestalten die neue Architektur für maximale Effizienz und höchste Sicherheit.",
    "home-automateDescription":
      "Cloud-Automatisierung ist der Einsatz automatisierter Tools und Prozesse zur Ausführung von Workflows in einer Cloud-Umgebung, die ansonsten manuell von Ihren Ingenieuren ausgeführt werden müssten. Anstatt Zeit und Ressourcen für die manuelle Verwaltung zu verschwenden, können Organisationen die Cloud-Automatisierung nutzen, um ihre Ressourcen auf Aktivitäten zu konzentrieren, welche einen direkten geschäftlichen Nutzen bringen, wie die Entwicklung neuer Services und die Erhöhung der Kundenzufriedenheit. Cloud-Automatisierung ist die einzige Möglichkeit, den größten Nutzen aus Ihrer Cloud-Umgebungen zu ziehen.",
    "home-readmore": "Mehr lesen",
    "home-ourCustomers": "Unsere Kunden",
    "home-caseStudies": "Case-Study",
    "home-stayTuned": ".Bleib up to date",
    "home-subscribe": "Erhalte alle Neuigkeiten über die Cloud",
    "header-sustainability": "nachhaltigkeit",
    "header-optimizeHeader": "optimierung",
    "header-developmentHeader": "entwicklung",
    "header-automateHeader": "automatisierung",
    "header-company": "firma",
    "header-contentHub": "content-hub",
    "header-contact": "kontakt",
    "header-webinars": "webinare",
    "header-reports": "reports",
    "header-caseStudies": "case-study",
    "header-cloudInsights": "cloud-insights",
    "header-partners": "partner",
    "header-ourTeam": "unser team",
    "header-ourStory": "unsere story",
    "header-news": "aktuelles",
    "header-careers": "karriere",
    "header-techBlog": "tech-blog",
    "header-opensource": "open source",
    "header-community": "community",
    "optimization-intro": ".Cloud Optimierung",
    "optimization-description":
      "Die nächste Generation von Anwendungen wird Cloud-nativ erstellt.​",
    "optimization-improvement": "Ständige Verbesserung",
    "optimization-improvementDescription":
      "Cloud Computing erfordert eine neue Art des Denkens über Themen wie Architektur und Design. Wir verstehen, dass es eine Herausforderung sein kann, mit dem Tempo der ganzen AWS-Innovationen Schritt zu halten und es Expertenwissen und eine Reihe von “Best Practices” benötigt, um das Maximum aus Ihrer Architektur herauszuholen. Wir bewerten Ihre Cloud-Umgebung, berichten über kritische Sicherheitslücken, empfehlen Modifikationen und gestalten die neue Architektur für maximale Effizienz und höchste Sicherheit.",
    "optimization-pillars": "Säulen",
    "optimization-pillarsTitle1": ".Operative Exzellenz",
    "optimization-pillarsTitle2": ".Sicherheit",
    "optimization-pillarsTitle3": ".Zuverlässigkeit",
    "optimization-pillarsTitle4": ".Effizienz",
    "optimization-pillarsTitle5": ".Kostenoptimierung",
    "optimization-pillarsDescription1":
      "Hier steht die Optimierung der Abläufe und die Überwachung der Ressourcen im Vordergrund. Ziel ist die kontinuierliche Verbesserung der Wertschöpfungskette durch optimierte Strukturen und Prozesse.",
    "optimization-pillarsDescription2":
      "Der Schutz von Daten und Systemen hat höchste Priorität. Es geht um Massnahmen zur Wahrung der Vertraulichkeit und der Datenintegrität, etwa durch eine geeignete Rechteverwaltung oder die Aufdeckung von Sicherheitsverstößen durch entsprechend eingerichtete Kontrollen.",
    "optimization-pillarsDescription3":
      "Fehlerbenachrichtigung und prompte, automatisierte Wiederherstellung der Systeme im Falle eines Ausfalls sorgen für hohe Zuverlässigkeit. Die Architektur ist darauf ausgelegt, Engpässe bei Ressourcen frühzeitig zu erkennen und weitere Kapazitäten bereitzustellen.",
    "optimization-pillarsDescription4":
      "Die optimale Nutzung von AWS-Ressourcen, auch für schwankende Anforderungen und unterschiedliche Systemanforderungen und Technologien. Die Auswahl der richtigen Services und Ressourcentypen, sowie die Berücksichtigung moderner Architekturparadigmen, wie z. B. “serverless”, stehen hier im Mittelpunkt.",
    "optimization-pillarsDescription5":
      "Um unnötige Kosten zu vermeiden bzw. zu reduzieren, erfolgt eine umfassende Analyse und Anpassung der Ressourcen. Mögliche Schritte sind hier zum Beispiel die Nutzung einer geplanten Kapazitätsreservierungen oder die Optimierung von genutzten Ressourcen.",
    "optimization-benefits": ".Vorteile",
    "optimization-benefitsHeading1":
      "Schnellere Entwicklung und Bereitstellung",
    "optimization-benefitsHeading2": "Reduzierung der Risiken",
    "optimization-benefitsHeading3": "Fundierte Entscheidungen",
    "optimization-benefitsHeading4": "Von bewährten Methoden lernen",
    "optimization-benefitsHeading5": "Kontinuierliche Verbesserung",
    "optimization-benefitsDescription1":
      "Kontinuierliche Rückmeldungen und Automatisierung sorgen für eine schnellere Bereitstellung von Anwendungsänderungen und neuen Features.",
    "optimization-benefitsDescription2":
      "Der Schutz von Informationen und Systemen einschließlich Vertraulichkeit und Integrität von Daten, Verwaltung von Rechten, Einrichtung von Kontrollen zur Erkennung von Sicherheitsereignissen.",
    "optimization-benefitsDescription3":
      "Verstehen Sie die Vor- und Nachteile von Entscheidungen, die Sie beim Erstellen von Systemen auf AWS treffen.",
    "optimization-benefitsDescription4":
      "Das “AWS Well-Architected Framework” besteht aus einer Reihe von bereits bewährten Methoden, die Sie beim Aufbau von robusten und agilen Anwendungen in der Cloud unterstützen.",
    "optimization-benefitsDescription5":
      "Die Anwendung von bewährten Methoden für die Architektur von AWS spart Ihnen Zeit, Kosten, steigert die Effizienz und bietet Ihnen einen faktenbasierten Verbesserungsplan.",
    "optimization-ourApproach": "Unser Vorgehen",
    "optimization-ourApproachHeading1": "Vorbereitung",
    "optimization-ourApproachHeading2": "Prüfung",
    "optimization-ourApproachHeading3": "Optimierung",
    "optimization-ourApproachDescription1":
      "Auswahl der zu optimierenden Applikationen, Beantwortung von Fragen und Diskussion weiterer Rahmenbedingungen.",
    "optimization-ourApproachDescription2":
      "Architektur und Anforderungen werden entsprechend dem “AWS Well-Architected Framework” detailliert betrachtet und diskutiert",
    "optimization-ourApproachDescription3":
      "Die Ergebnisse werden ausgewertet und konkrete Verbesserungsmaßnahmen werden erarbeitet. Durchführung der mittel- und langfristigen Maßnahmen.",
    "optimization-cloudInsights": "Entdecke die neuesten Cloud Updates",
    "optimization-showAll": "Alle zeigen",
    "optimization-contactHeading": "Optimierung und Modernisierung",
    "contact-lookingFor":
      "Du suchst nach einem Team, das immer die Extrameile für dich geht? Wir sind für alle Aufgaben bereit!",
    "development-heading": ".Cloud-native Entwicklung",
    "development-description":
      "Die nächste Generation von Anwendungen wird Cloud-nativ erstellt.​",
    "development-buildCloud": "Entwickelt für die Cloud",
    "development-buildCloudDescription":
      "Um in schnelllebigen, software-getriebenen Märkten erfolgreich zu sein, müssen Unternehmen die Art und Weise ändern wie sie Anwendungen entwerfen, erstellen und nutzen. Cloud-native Anwendungsentwicklung ist ein Ansatz zum Erstellen, Ausführen und Verbessern von Applikationen, welcher auf vertrauten Techniken und Technologien für Cloud Computing basiert. Cloud-native App-Entwicklung umfasst typischerweise DevOps, agile Methodik, Microservices, verschiedene Cloud-Plattformen, Container und “serverless Delivery” – kurz gesagt, jede neue und moderne Methode der Anwendungsbereitstellung.",
    "development-benefitsHeading1": "Verkürzung der Markteinführungszeit",
    "development-benefitsHeading2": "Verkürzt die Release-Zyklen",
    "development-benefitsHeading3": "Skalierbar und Robust",
    "development-benefitsHeading4": "Kosteneffizienz",
    "development-benefitsHeading5": "Unübertroffene Agilität",
    "development-benefitsDescription1":
      "Die Cloud Native-Architektur ermöglicht höhere Agilität und Erweiterbarkeit, da Unternehmen neue Module hinzufügen oder aktualisieren können ohne komplette Anwendungen neu erstellen zu müssen.",
    "development-benefitsDescription2":
      "Nutzen Sie branchenführende Cloud-native Designmuster, moderne Anwendungsentwicklung,“12-Factor” App-Entwicklungsprinzipien sowie Agile- und DevOps-Methodik.",
    "development-benefitsDescription3":
      "Skalieren Sie nahtlos und auf Abruf durch unvorhersehbaren Datenverkehr und bleiben Sie bei unerwarteten Ausfällen ohne großen Aufwand belastbar.",
    "development-benefitsDescription4":
      "Mit der dynamischen Skalierung können Sie ihre Rechenressourcen nach Bedarf und nach Auslastung beliebig erhöhen oder verringern.",
    "development-benefitsDescription5":
      "Reduzieren Sie Entwicklungskosten, Zeit und Aufwand und gewinnen Sie Energie zurück, die Sie stattdessen für Innovationen und die Entwicklung erstaunlicher Produkte verwenden könnten.",
    "development-ourApproachHeading1": "Fokus auf serverlose Technologie",
    "development-ourApproachHeading2": "End-to-End-Service",
    "development-ourApproachHeading3": "Sprint-basiert & agile Methodik",
    "development-ourApproachDescription1":
      "Ausgewählte Software-Architekten und Ingenieure mit dem Fokus und Know-how auf ausschließlich serverlose Anwendungsentwicklung.",
    "development-ourApproachDescription2":
      "Bereitstellung aller Backend-Anwendungen, APIs, Integrationen und Benutzeroberflächen um aus Ihrer Idee eine vollständig realisierte Anwendung zu machen.",
    "development-ourApproachDescription3":
      "Kollaborativer und flexibler Ansatz der sich schnell an neue Herausforderungen anpasst und den Projekterfolg sichert.",
    "automate-heading": ".Automatisierung in der AWS Cloud",
    "automate-description":
      ".Schnelle, sichere und moderne Softwarebereitstellung",
    "automate-bannerHeading": "Agilität, Skalierbarkeit und Kontrolle",
    "automate-bannerDescription":
      "Cloud-Automatisierung ist der Einsatz automatisierter Tools und Prozesse zur Ausführung von Workflows in einer Cloud-Umgebung, die ansonsten manuell von Ihren Ingenieuren ausgeführt werden müssten. Anstatt Zeit und Ressourcen für die manuelle Verwaltung zu verschwenden, können Organisationen die Cloud-Automatisierung nutzen, um ihre Ressourcen auf Aktivitäten zu konzentrieren, welche einen direkten geschäftlichen Nutzen bringen, wie die Entwicklung neuer Services und die Erhöhung der Kundenzufriedenheit. Cloud-Automatisierung ist die einzige Möglichkeit, den größten Nutzen aus Ihrer Cloud-Umgebungen zu ziehen.",
    "automate-service": "Automatisierungs Services",
    "automate-serviceDescription1":
      "DevOps-Automatisierung beschreibt das Vorgehen, wiederholende und manuelle DevOps-Aufgaben vollständig zu automatisieren, sodass diese ohne jegliche menschliche Interaktion ausgeführt werden. Die Automatisierung kann über den gesamten DevOps-Lebenszyklus hinweg angewendet werden: Design und Entwicklung, Software-Bereitstellung und -Release, Monitoring & Logging. Das Ziel ist die Optimierung des gesamten DevOps-Lebenszyklus durch die Reduzierung von manueller Arbeitsbelastung.",
    "automate-serviceDescription2":
      "DevSecOps bedeutet Sicherheit in die App-Entwicklung von Anfang bis Ende zu integrieren. Diese Integration in die Pipeline erfordert eine neue organisatorische Denkweise ebenso wie neue Werkzeuge. Automatisieren Sie die Sicherheit zum Schutz der gesamten Umgebung und der Daten, sowie des kontinuierlichen Integrations-/Bereitstellungsprozesses – ein Ziel, das die Sicherheit von Microservices in Containern und Serverless einschließen wird.",
    "automate-serviceDescription3":
      "Verbessern Sie die Automatisierung, Steuerung und Qualität der Produktion durch Continuous Delivery (CD) -Pipelines und Self-Service-Portale. Eine ML-Pipeline in der Produktion liefert kontinuierlich Vorhersagen für neue Modelle, die auf neue Daten geschult werden. Das trainierte und validierte Modell, welches innerhalb des Deployment-Schrittes als Inferenz dient, wird vollständig automatisiert.",
    "automate-benefitsHeading1": "Verbesserte Zusammenarbeit",
    "automate-benefitsHeading2": "Niedrigere Kosten",
    "automate-benefitsHeading3": "Beschleunigte Innovation",
    "automate-benefitsHeading4": "Erhöhte Effizienz",
    "automate-benefitsHeading5": "Reduziert Ausfälle",
    "automate-benefitsHeading6": "Hochqualitative Produkte",
    "automate-benefitsDescription1":
      "Eliminiert typische Herausforderungen zwischen verschiedenen Rollen durch einen automatisierten und koordinierten Prozess.",
    "automate-benefitsDescription2":
      "Reduziert manuelle Aufgaben, wodurch Sie Ihre Entwicklungskapazität erhöhen und Fehler reduzieren können.",
    "automate-benefitsDescription3":
      "Integrierte Betriebs- und Entwicklungsteams führen zu einer schnelleren Entwicklung und Bereitstellung.",
    "automate-benefitsDescription4":
      "Automatisierte Werkzeuge und standardisierte Produktionsplattformen machen den Einsatz deutlich berechenbarer.",
    "automate-benefitsDescription5":
      "Wenn Änderungen klein und ausgeprägt sind, anstatt groß und weitreichend, sind sie sicherer. Nicht nur die Wahrscheinlichkeit eines Ausfalls nimmt ab, sondern ebenfalls die Erholungszeit.",
    "automate-benefitsDescription6":
      "Produkte werden mit deutlich weniger Fehlern bereitgestellt und durch die häufigen Bereitstellungen und die kurzen Zykluszeiten, können Produkte schneller und effizienter verbessert werden.",
    "automate-ourApproachHeading1": "Entdecken",
    "automate-ourApproachHeading2": "Entwickeln",
    "automate-ourApproachHeading3": "Befähigen",
    "automate-ourApproachDescription1":
      "Initiale Bewertung wie der Kunde aktuell mit dem Erstellen, Testen, Bereitstellen und Freigeben seiner Anwendungen umgeht.",
    "automate-ourApproachDescription2":
      "Entwicklung der Automatisierungsplattform auf AWS mit vollautomatisierter Infrastruktur und Implementierungspipeline.",
    "automate-ourApproachDescription3":
      "Von Experten geführtes Beratungs- und Befähigungsprogramm. Unsere erfahrenen Ingenieure schulen Ihr Team um Ihnen die Bereitstellung auch im großen Stile zu ermöglichen.",
    "partners-heading": ".Partner",
    "partners-description":
      "Jeder von uns kann etwas bewegen – doch gemeinsam verändern wir die Welt. Unsere starke Partnerallianz.",
    "partners-awsDescription":
      "Amazon Web Services (AWS) ist die weltweit umfassendste und am weitesten gefasste Cloud-Plattform und bietet über 200 voll ausgestattete Services aus weltweit verteilten Rechenzentren – On-Demand, in Sekundenschnelle und mit Pay-as-you-go-Modellen. Als “AWS Advanced Consulting Partner” wurde globaldatanet mit der AWS DevOps Competency ausgezeichnet. Als “AWS Well-Architected Framework Partner” unterstützen wir unsere Partner bei der Optimierung ihrer Cloud-nativen Anwendungen in den Bereichen Sicherheit, Kosten, Zuverlässigkeit, Operational Excellence und Performance.",
    "partners-learnMore": "Lerne mehr",
    "partners-techHeading": "Technologie",
    "partners-techPartner1":
      "HashiCorp ist führend in der Infrastrukturautomatisierung für Multi-Cloud-Umgebungen. Sie entwickeln Lösungen, die Innovationen in globalen Unternehmen ermöglichen und Ihnen ein einheitliches Cloud-Betriebsmodell über konsistente Workflows bieten, um Ihre Infrastruktur mit beliebigen Anwendungen bereitzustellen, zu sichern, zu vernetzen und zu betreiben. Alle Basistechnologien sind Open Source und wurden seit ihrer Gründung offen entwickelt.",
    "partners-techPartner2":
      "Datadog ist eine Überwachungs-, Sicherheits- und Analyseplattform für Cloud-Anwendungen. Die SaaS-Plattform integriert und automatisiert Infrastruktur-Monitoring, Anwendungs-Performance-Monitoring und Log-Management, um eine einheitliche Echtzeitbeobachtung Ihres gesamten Technologie-Stacks zu gewährleisten.",
    "partners-techPartner3":
      "Sysdig ist ein Anbieter mit Fokus auf Cloud- und Containersicherheit für Unternehmen und deren DevOps-Teams. Sie fördern die sichere DevOps-Nutzung und helfen Organisationen dabei Container, Kubernetes und Cloud-Dienste zuverlässig zu sichern. Mit der “Sysdig Secure DevOps Platform” sichern Cloud-Teams die Build-Pipeline, erkennen und reagieren auf Laufzeitbedrohungen, überprüfen kontinuierlich Compliance und überwachen Infrastrukturen und Services.",
    "partners-techPartner4":
      "OHTRU ist eine “FinOps Platform Collaborative”, Echtzeit-Cloud-Finanzmanagement und Cloud-Kostenoptimierung für mehrere Clouds, Teams und Konten. OHTRU unterstützt SRE-, DevOps- und FinOps-Teams dabei effizientere Architekturen zu entwickeln und die Cloud-Kosten zu senken.",
    "partners-techPartner5":
      "Hasura macht den Datenzugriff einfach, indem es sofort eine GraphQL-API zusammenstellt, die durch Datenbanken und Dienste unterstützt wird. Entwickler können Geschäftslogik erstellen, die Ereignisse veröffentlicht und konsumiert, die auf Datenänderungen reagiert oder zeitlich geplant wird. Hasura automatisiert 50-80% Ihrer API-Entwicklungsarbeit und macht Ihre Anwendungsentwicklung oder Ihr Modernisierungsprojekt überschaubar und in einem viel kürzeren Zeitrahmen.",
    "partners-businessPartner1":
      "Aufgrund der zunehmenden Spezialisierung und des Mangels an IT-Spezialisten wird es für Unternehmen immer wichtiger, externe Experten und Ressourcen schnell und effizient zu finden und in laufende Projekte einzubinden. Die Software-Allianz Deutschland ist ein formaler Zusammenschluss von 120 mittelständischen IT-Dienstleistern und unterstützt Unternehmen und Organisationen vor Ort.",
    "partners-businessPartner2":
      "EuroCloud Native (ECN) ist eine Initiative von EuroCloud Deutschland, dem Verband der Cloud Computing-Industrie in Deutschland. Die Initiative repräsentiert die Cloud-native Industrie in Deutschland, macht den Markt transparent und bringt Anbieter, Anwender und Hyperscaler ins Gespräch. Neben einem Fachforum versteht sich ECN auch als Ansprechpartner für die Medien.",
    "partners-businessPartner3":
      "realizon baut speziell zusammengestellte Engineering-Teams für Sie auf. Sie bieten Ihnen alles, was Sie benötigen, um Ihr bestehendes Projekt erfolgreich zu erweitern oder von Grund auf neu zu starten und um ein großartiges Konzept in ein leistungsstarkes Softwareprodukt zu verwandeln. Der gesamte Fokus liegt hierbei in der Exzellenz der Softwareentwicklung: Für ihre Kunden erzielen sie hervorragende Ergebnisse, indem sie ein Projektumfeld schaffen, das einige der weltweit besten Softwareentwickler anzieht und ihren Kunden den Wettbewerbsvorteil verschafft, den sie für ihren Erfolg brauchen.",
    "partners-businessPartner4":
      "Ginkgo Analytics ist ein Teil von Ginkgo Management Consulting mit Sitz in Hamburg. Sie beraten Unternehmen in den Bereichen Data Analytics, Big Data und Machine Learning und liefern produktive Lösungen mit hohem Mehrwert. Die Mission von Ginkgo Analytics ist es, ihre Kunden in datengetriebene und zukunftsorientierte Unternehmen zu verwandeln.",
    "partners-businessPartner5":
      "“wherever SIM” steht für zuverlässige und sichere M2M-SIM-Karten, die eine Vielzahl von IoT-Geräten weltweit mit dem Internet verbinden. Dank nationalem Roaming wählen sich ihre SIM-Karten immer automatisch in das beste Mobilfunknetz an einem Standort ein. Kombiniert mit flexiblen, auf die Bedürfnisse des jeweiligen Projekts abgestimmten Datentarifen und umfassender Hardware-Expertise, bieten sie für jedes Projekt die passende Lösung.",
    "ourstory-title": ".Unsere Story",
    "ourstory-description":
      "Mit mehr als 4 Jahren Erfahrung haben wir uns tiefes Wissen in der Entwicklung und Modernisierung von Cloud Anwendungen erarbeitet.",
    "ourstory-beUniqueHeading": "Sei einzigartig und denk anders",
    "ourstory-beUniqueDescription":
      "globaldatanet ist ein Cloud-Natives Unternehmen, welches sich auf die Entwicklung und die Optimierung von Plattformen und Anwendungen in der AWS Cloud spezialisiert hat. Wir befähigen Kunden auf der ganzen Welt, das volle Potenzial der Cloud zu nutzen, durch neue Produkte den Umsatz zu steigern oder durch Verbesserung von bestehenden Anwendungen die Effizienz zu erhöhen. Wir setzen auf pragmatische Lösungen, die exakt auf die Bedürfnisse unserer Kunden zugeschnitten sind, ohne dabei die Einfachheit und Effizienz aus den Augen zu verlieren. Was macht uns einzigartig? Wir denken anders, wir schätzen langjährige Partnerschaften und vor allem lieben wir, was wir tun und diese Leidenschaft stecken wir in unsere Projekte.",
    "ourstory-ourTeam": "Unser Team",
    "ourstory-joinOurTeamHeading": "Werde Teil unseres Team",
    "ourstory-joinOurTeamDescription1": ".Du willst Fortschritt?",
    "ourstory-joinOurTeamDescription2": "Dann sei ein Teil davon.",
    "ourstory-searchCareer": "Suche deine Karriere",
    "ourstory-behindSceneHeading": "Der Antrieb unseres Erfolgs ",
    "ourstory-behindSceneDescription":
      "Die Geschichten unser innovativen Cloud-Lösungen beginnen mit unserem Team. Wir sind ein Team von vielseitigen Spezialisten, nicht Generalisten. Jeder konzentriert sich auf seine Kernbereiche und baut ein fundiertes Expertenwissen auf, um höchste Qualität und fundiertes Know-how in den verschiedensten Bereichen zu gewährleisten.",
    "ourstory-cloudValue": "Unsere Werte:",
    "ourstory-cloudValueDescription":
      "6 Werte sind nicht viel, um uns zu beschreiben, aber es ist ein Anfang",
    "ourstory-cloudValueHeading1": ".Liebe was du tust",
    "ourstory-cloudValueHeading2": ".Lernen und neugierig sein",
    "ourstory-cloudValueHeading3": ".Menschen zuerst",
    "ourstory-cloudValueHeading4": ".Vertrauen",
    "ourstory-cloudValueHeading5": ".Mehr ist nicht immer besser",
    "ourstory-cloudValueHeading6": ".Verrückte Ideen & Kreativität",
    "ourstory-cloudValueDesc1":
      "Talent ohne Leidenschaft ist wie eine Lambda-Funktion ohne Argumente.",
    "ourstory-cloudValueDesc2":
      "Wie kann man besser werden? Motivation, Neugier, Leidenschaft und das Streben nach neuen Technologien. Das ist alles, was man braucht!",
    "ourstory-cloudValueDesc3":
      "Das Team ist die wichtigste Ressource. Füreinander sorgen, sich gegenseitig unterstützen und gemeinsam Spaß haben.",
    "ourstory-cloudValueDesc4":
      "Langjährige Partnerschaften basieren auf Vertrauen, Transparenz und Ehrlichkeit. Darauf legen wir Wert.",
    "ourstory-cloudValueDesc5":
      "Nichts ist schlimmer, als mit einer überentwickelten App zu enden. Wir lieben es, innovative Lösungen zu erfinden und dabei die Dinge einfach und effizient zu halten.",
    "ourstory-cloudValueDesc6":
      "Ganz normal? Nicht in unserem Unternehmen! Wir denken anders und mögen verrückte Ideen, um etwas Besonderes und Einzigartiges zu schaffen.",
    "ourstory-newsRoom": "Unsere Neuigkeiten",
    "ourstory-viewAll": "Alle ansehen",
    "careers-title": ".Werde ein Creator",
    "careers-description":
      "Mit mehr als 4 Jahren Erfahrung im Bereich der Cloud-nativen Entwicklung haben wir eine unerreichte Wissenstiefe aufgebaut.",
    "careers-apply": "Jetzt Bewerben",
    "careers-thatUsHeading": "Das sind wir",
    "careers-thatUsDesc":
      "Das Team ist der wichtigste Faktor. Wir lieben, was wir tun und wir lieben es, innovative Lösungen zu entwickeln und Dinge anders zu machen, als andere. Bei globaldatanet zu arbeiten bedeutet, sich gegenseitig zu unterstützen, gemeinsam Spaß zu haben und einzigartig zu sein.",
    "careers-coreValue": "Grundwerte",
    "careers-coreValue1": "Liebe was du tust",
    "careers-coreValue2": "Unterstützt euch gegenseitig",
    "careers-coreValue3": "Lerne und sei neugierig",
    "careers-coreValue4": "Menschen zuerst",
    "careers-coreValue5": "Vertrauen",
    "careers-coreValue6": "Erfinde & Vereinfache",
    "careers-coreValue7": "Verrückte Ideen & Kreativität",
    "careers-coreValue8": "Hab Spaß",
    "careers-coreValue9": "Gemeinschaft",
    "careers-benefits": "Deine Vorteile",
    "careers-benefitsHeading1": "Kultur & Entwicklung",
    "careers-benefitsHeading2": "Gemeinschaft",
    "careers-benefitsHeading3": "Work-Life",
    "careers-culture1": "Offene Feedback-Kultur & Raum für Innovation",
    "careers-culture2": "Agile Arbeitsmethoden",
    "careers-culture3": "Schulungsbudget & Zertifizierungsprämien",
    "careers-culture4":
      "Flache Hierarchien für kreative Ideen und unkonventionelle Ansätze",
    "careers-culture5": "Arbeiten mit Spitzentechnologien",
    "careers-culture6": "Schnelle und übersichtliche Entscheidungsprozesse",
    "careers-culture7": "Arbeiten mit Freunden",
    "careers-community1": "Unvergessliche Events & Partys",
    "careers-community2": "Wöchentlich Tech & Beer",
    "careers-community3": "Top-Ausrüstung",
    "careers-community4": "Monatliche Auswahl an Geschenkgutscheinen",
    "careers-community5": "Tank-Top oder Sandalen – sei Du selbst",
    "careers-community6":
      "Besuche deine Freunde an unseren verschiedenen Standorten",
    "careers-community7": "Drinks & Snacks",
    "careers-workLife1":
      "Das Leben passiert und die Arbeit kommt nicht immer an erster Stelle. Wir unterstützen dabei einen Ausgleich zwischen beidem zu finden.",
    "careers-workLife2": "Wählen deine Arbeits- und Bürozeiten flexibel",
    "careers-workLife3": "28 Tage Urlaub + 24.12 & 31.12 zusätzlich frei",
    "careers-workLife4": "Wöchentliche “Happy Hour”",
    "careers-workLife5": "Keine längeren Reisen",
    "careers-workLife6": "Hundefreundliches Büro",
    "careers-inNunbers1": "Talente",
    "careers-inNunbers2": "Standorte",
    "careers-inNunbers3": "Tech-Rate",
    "careers-inNunbers4": "APN-Ambassador",
    "careers-inNunbers5": "Server",
    "careers-inNunbers6": "AWS Community Builders",
    "careers-inNunbers7": "Sprachen",
    "careers-inNunbers8": "Zertifizierung",
    "careers-process": ".Bewerbungsprozess",
    "careers-processHeading1": ".Online Bewerben",
    "careers-processHeading2": ".Intro-Call",
    "careers-processHeading3": ".Technisches Interview",
    "careers-processHeading4": ".Zweites Interview",
    "careers-processHeading5": ".Angebot und Vertrag",
    "careers-processDesc1":
      "Zur Bewerbung musst du lediglich deinen Lebenslauf hochladen. Zusätzlich nutzen wir einen kurzen Online-Fragebogen um dich noch besser kennenzulernen.",
    "careers-processDesc2":
      "Der Intro Call dient vor allem dazu Ihnen einen besseren Überblick über globaldatanet und alles drumherum zu geben.",
    "careers-processDesc3":
      "Während des Fachgesprächs tauchen wir meist in ein technisches Gespräch ein, bei dem wir in entspannter Atmosphäre deine Erfahrungen mit verschiedenen Technologien besprechen.",
    "careers-processDesc4":
      "Das 2. Interview ist eine Mischung aus den Calls davor. Unser CEO gibt dir einen detaillierten Überblick über unsere Vision und wir werden einige technische und nicht-technische Fragen durchgehen.",
    "careers-processDesc5":
      "In der ersten Phase befassen wir uns mit den ungefähre Rahmenbedingungen. Wir machen ein erstes Angebot. Wenn alle Bedingungen vereinbart sind werden wir den Vertrag abschließen und “Willkommen in unserem Team” !",
    "careers-insights": "Einblicke",
    "careers-inNumbers": "In Zahlen",
    "contact-title": "Kontaktiere uns",
    "contact-description":
      "Du suchst nach einem Team, das immer die Extrameile für dich geht? Wir sind für alle Aufgaben bereit!",
    "contact-submit": "Schreib uns",
    "contact-writeUs": "Schreib uns",
    "contact-allow":
      "Ich erlaube dieser Website meine Eingabe zu speichern, damit auf meine Anfrage geantwortet werden kann.*",
    "contact-firstName": "Vorname*",
    "contact-lastName": "Nachname*",
    "contact-email": "E-mail*",
    "contact-phone": "Telefonnummer",
    "contact-company": "Firma",
    "contact-message": "Nachricht*",
    "contact-automateHeading": "Bringe deine Anwendung auf ein neues Level",
    "contact-caseStudies":
      "Bist du bereit, Dein Unternehmen auf ein neues Level zu bringen?",
    "contact-optimizeHeading": "Optimierung durch Cloud-native Experten",
    "contact-developmentHeading": "Lassen Sie uns Ihre neue Idee besprechen",
    "imprint-title": "Impressum",
    "imprint-info": "Erforderliche Angaben nach § 5 Abs. 1 DDG",
    "imprint-chief": "Geschäftsführung: ",
    "imprint-contact": "Kontakt:",
    "imprint-phone": "Telefon:",
    "imprint-email": "E-Mail:",
    "imprint-register": "Gerichtsstand",
    "imprint-court": " ",
    "imprint-number": " ",
    "imprint-tax": "Steuer",
    "imprint-taxID": "Ust-Id:",
    "imprint-taxNo": "Steuernummer:",
    "privacy-title": "Datenschutz",
    "privacy-text1-1":
      'Personenbezogene Daten (im Folgenden meist nur "Daten" genannt) \n\n werden von uns nur im erforderlichen Umfang und zum Zweck der Bereitstellung einer funktionsfähigen und benutzerfreundlichen Website einschließlich ihrer Inhalte und der dort angebotenen Dienste verarbeitet.',
    "privacy-text1-2":
      'Nach Art. 4 Nr. 1 der Verordnung (EU) 2016/679, d.h. der Datenschutz-Grundverordnung (nachfolgend "DSGVO"), bezeichnet "Verarbeitung" jeden mit oder ohne Hilfe automatisierter Verfahren ausgeführten Vorgang oder jede Vorgangsreihe wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung, die Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die Einschränkung, das Löschen oder die Vernichtung von personenbezogenen Daten.',
    "privacy-text1-3":
      "Die nachfolgende Datenschutzerklärung soll Sie insbesondere über die Art, den Umfang, den Zweck, die Dauer und die Rechtsgrundlage der Verarbeitung solcher Daten in eigener oder fremder Verantwortung informieren. Wir informieren Sie im Folgenden auch über die Komponenten Dritter, die wir zur Optimierung unserer Website und zur Verbesserung der Nutzererfahrung einsetzen, was dazu führen kann, dass diese Dritten ebenfalls Daten verarbeiten, die sie sammeln und kontrollieren.",
    "privacy-structure": "Unsere Datenschutzpolitik ist wie folgt aufgebaut:",
    "privacy-structure1":
      "Informationen über uns als für die Verarbeitung Ihrer Daten Verantwortliche",
    "privacy-structure2":
      "Informationen über uns als Verantwortliche für die Verarbeitung Ihrer Daten",
    "privacy-structure3": "Informationen über die Datenverarbeitung",
    "privacy-sec1Heading":
      "Informationen über uns als Verantwortliche für die Verarbeitung Ihrer Daten",
    "privacy-sec1Desc":
      'Verantwortliche Stelle im Sinne des Datenschutzrechts für diese Website (der "Verantwortliche") ist:',
    "privacy-sec1Phone": "Telefon",
    "privacy-sec2Heading": "Rechte der Nutzer und betroffenen Personen",
    "privacy-sec2list1":
      "Im Hinblick auf die nachfolgend näher zu beschreibende Datenverarbeitung haben Nutzer und Betroffene das Recht auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die verarbeiteten Daten, auf weitere Informationen über die Art der Datenverarbeitung sowie auf Kopien der Daten (vgl. auch Art. 15 DSGVO);",
    "privacy-sec2list2":
      "das Recht auf Berichtigung oder Vervollständigung unrichtiger oder unvollständiger Daten (vgl. auch Art. 16 DSGVO);",
    "privacy-sec2list3":
      "auf die unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), hilfsweise bei Erforderlichkeit der weiteren Verarbeitung nach Maßgabe des Art. 17 Abs. 3 GDPR, die Einschränkung der Verarbeitung nach Art. 18 DSGVO;",
    "privacy-sec2list4":
      "Kopien der sie betreffenden bzw. von ihnen bereitgestellten Daten zu erhalten und diese an andere Anbieter/Verantwortliche übermitteln zu lassen (vgl. auch Art. 20 DSGVO);",
    "privacy-sec2list5":
      "Beschwerden bei der Aufsichtsbehörde einzureichen, wenn sie der Meinung sind, dass die sie betreffenden Daten von dem für die Verarbeitung Verantwortlichen unter Verstoß gegen die Datenschutzbestimmungen verarbeitet werden (vgl. auch Art. 77 DSGVO).",
    "privacy-sec2text1":
      "Darüber hinaus ist der für die Verarbeitung Verantwortliche verpflichtet, alle Empfänger, an die er Daten weitergibt, über solche Berichtigungen, Löschungen oder Einschränkungen der Verarbeitung derselben gemäß Art. 16, 17 Abs.. 1, 18 GDPR. Diese Pflicht besteht jedoch nicht, wenn eine solche Benachrichtigung unmöglich ist oder einen unverhältnismäßigen Aufwand erfordert. Dennoch haben die Nutzer ein Recht auf Auskunft über diese Empfänger. Desgleichen haben gemäß Art. 21 DSGVO haben Nutzer und betroffene Personen das Recht, der künftigen Verarbeitung ihrer Daten durch den Verantwortlichen gemäß Art. 6 Abs. 1 lit. f) GDPR. Insbesondere ist ein Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung zulässig.",
    "privacy-sec3Heading": "Information über die Datenverarbeitung",
    "privacy-sec3text1":
      "Wenn Sie per E-Mail oder über das Kontaktformular Kontakt mit uns aufnehmen, werden die von Ihnen mitgeteilten Daten zum Zweck der Bearbeitung Ihrer Anfrage verarbeitet. Wir benötigen diese Daten, um Ihre Anfrage bearbeiten und beantworten zu können; andernfalls können wir sie nicht oder nicht vollständig beantworten. Die Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. b) GDPR. Die Löschung Ihrer Daten erfolgt, wenn wir Ihre Anfrage vollständig beantwortet haben und keine weiteren gesetzlichen Aufbewahrungspflichten bestehen, z.B. wenn sich daraus ein Auftrag oder Vertrag ergibt.",
    "privacy-sec3SocialHeading": "Social-Media-Links über Grafiken",
    "privacy-sec3SocialText1":
      "Wir binden auch die folgenden Social-Media-Seiten in unsere Website ein. Die Einbindung erfolgt über eine verlinkte Grafik der jeweiligen Seite. Durch die Verwendung dieser auf unseren eigenen Servern gespeicherten Grafiken wird die automatische Verbindung zu den Servern dieser Netzwerke zur Darstellung verhindert. Erst wenn Sie auf die entsprechende Grafik klicken, werden Sie zum Dienst des jeweiligen sozialen Netzwerks weitergeleitet. Sobald Sie klicken, kann dieses Netzwerk Informationen über Sie und Ihren Besuch auf unserer Website aufzeichnen. Es kann nicht ausgeschlossen werden, dass diese Daten in den Vereinigten Staaten verarbeitet werden.",
    "privacy-sec3SocialText2":
      'Zu diesen Daten gehören zunächst unter anderem Ihre IP-Adresse, das Datum und die Uhrzeit Ihres Besuchs sowie die besuchte Seite. Wenn Sie jedoch in Ihrem Benutzerkonto in diesem Netzwerk angemeldet sind, kann der Netzwerkbetreiber die über Ihren Besuch auf unserer Website gesammelten Informationen Ihrem persönlichen Konto zuordnen. Wenn Sie interagieren, indem Sie auf "Gefällt mir", "Teilen" usw. klicken, können diese Informationen in Ihrem persönlichen Benutzerkonto gespeichert und möglicherweise in dem jeweiligen Netzwerk veröffentlicht werden. Um dies zu verhindern, müssen Sie sich vor dem Anklicken der Grafik bei Ihrem Social-Media-Konto abmelden. Die verschiedenen Social-Media-Netzwerke bieten auch Einstellungen an, die Sie entsprechend konfigurieren können.',
    "privacy-sec3SocialSubHeading":
      "Die folgenden sozialen Netzwerke sind durch verlinkte Grafiken in unsere Seite eingebunden:",
    "privacy-sec3Twitter":
      "Wir unterhalten eine Online-Präsenz auf Twitter, um unser Unternehmen und unsere Dienstleistungen zu präsentieren und um mit Kunden/Interessenten zu kommunizieren. Twitter ist ein Dienst der Twitter Inc. mit Sitz in 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Wir weisen darauf hin, dass dies dazu führen kann, dass Nutzerdaten außerhalb der Europäischen Union, insbesondere in den Vereinigten Staaten, verarbeitet werden. Dadurch können sich die Risiken für die Nutzer erhöhen, die z.B. einen späteren Zugriff auf die Nutzerdaten erschweren können. Auch wir haben keinen Zugriff auf diese Nutzerdaten. Der Zugriff ist nur für Twitter möglich. Twitter Inc. ist unter dem Privacy Shield zertifiziert und verpflichtet sich zur Einhaltung der europäischen Datenschutzstandards.",
    "privacy-sec3TwitterPolicy":
      "Die Datenschutzrichtlinien von Twitter finden Sie unter",
    "privacy-sec3Ig":
      "Der für die Verarbeitung Ihrer Daten Verantwortliche ist Facebook Irland. Sie können dieses Unternehmen entweder online oder per Post kontaktieren unter: Facebook Ireland Ltd. 4 Grand Canal Square Grand Canal Harbor Dublin 2 Irland",
    "privacy-otherHeading": "Cookies",
    "privacy-googleHeading": "Google-Analytics (optional)",
    "privacy-googleText":
      'Unsere Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. ("Google"). Die durch den Cookie erzeugten Informationen über Ihre Benutzung unserer Website (einschließlich Ihrer IP-Adresse) werden an einen Server von Google in den Vereinigten Staaten übertragen und dort gespeichert. Google wird diese Informationen benutzen, um Ihre Nutzung unserer Website auszuwerten, um Reports über die Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird Ihre IP-Adresse nicht mit anderen von Google gespeicherten Daten in Verbindung bringen. Weitere Informationen über die Datenschutzbestimmungen von Google finden Sie unter',
    "privacy-enchargeText":
      "Personenbezogene Daten können vom Nutzer freiwillig zur Verfügung gestellt werden oder, im Falle von Nutzungsdaten, automatisch bei der Nutzung dieser Website erfasst werden. Die Nutzer sind für alle persönlichen Daten Dritter verantwortlich, die über diese Website erhalten, veröffentlicht oder weitergegeben werden, und bestätigen, dass sie die Zustimmung des Dritten zur Weitergabe der Daten an den Eigentümer haben.",
    "thankyou-reports":
      "Vielen Dank für Dein Interesse an unserem Report. Als nächstes schicken wir dir eine E-Mail mit einem Bestätigungslink. Falls du bereits abonniert hast, entfällt diese E-Mail und du erhältst direkt eine Nachricht mit dem Download des Reports in dein Postfach.",
    "thankyou-webinars":
      "Vielen Dank für Dein Interesse an unserem Webinar. Von nun an kannst Du live dabei sein bei den spannendsten Vorträgen rund um die Serverless World. Als nächstes schicken wir dir eine E-Mail mit einem Bestätigungslink. Falls du bereits abonniert hast, entfällt diese E-Mail und du erhältst direkt eine Nachricht mit weiteren Informationen zum Webinar.",
    "thankyou-contact":
      "Vielen Dank für Deine Anfrage. Als nächstes schicken wir dir eine E-Mail mit einem Bestätigungslink. Falls du bereits abonniertin der Vergangenheit unseren Content zugeschickt bekommen hast, entfällt diese E-Mail. Wir schauen uns deine Anfrage an und werden schnellstmöglich per E-Mail auf dich zukommen umund die individuellen nächsten Schritte zu besprechen.",
    "thankyou-casestudy":
      "Vielen Dank für deine Anfrage. Als nächstes senden wir dir eine E-Mail mit einem Bestätigungslink zu. Falls du unseren Content bereits in der Vergangenheit abonniert hast, entfällt diese E-Mail. Wir werden deine Anfrage sorgfältig prüfen und uns umgehend per E-Mail mit dir in Verbindung setzen, um die individuellen nächsten Schritte zu besprechen.",
    "thankyou-subscribe":
      "Vielen Dank für Deine Bestätigung und Dein Interesse an unserem Content. Du erhältst von nun an spannende Infos und Neuigkeiten aus der Serverless World direkt in Dein Email-Postfach.",
    "thankyou-newsletter":
      "Vielen Dank für Ihr Interesse an unserem fantastischen Cloud-Newsletter. Als nächstes senden wir Ihnen eine E-Mail mit einem Bestätigungslink. Wenn Sie in der Vergangenheit bereits abonniert waren, wird diese E-Mail übersprungen.",
    "thankyou-backHome": "Zurück zur Startseite",
    "thankyou-title": ".Vielen Dank",
    "thankyou-solutions":
      "Vielen Dank für Dein Interesse an unseren spannenden Cloud-Lösungen. Als nächstes senden wir Dir eine E-Mail mit einem Bestätigungslink. Wenn Du dich bereits in der Vergangenheit angemeldet hast, wird diese E-Mail übersprungen und Du erhälst eine Nachricht, um weitere Informationen über die gewünschte Lösung zu erhalten.",
    "case-studies-challenge": "Herausforderung",
    "case-studies-solution": "Lösung",
    "case-studies-benefits": "Vorteile",
    "case-studies-heading":
      "Tauchen Sie ein in einige unserer einzigartigen Erfolgsgeschichten, und verschaffen Sie sich einen besseren Überblick über die innovativen Lösungen, die wir entwickeln, um Unternehmen zu helfen, die nächste Stufe zu erreichen.",
    "scoping-call-heading": ".Bist du bereit für das nächste Level?",
    "scoping-call-description":
      "Digitalisierungen und Optimierungen, die am besten zu deinen Unternehmenszielen passen",
    "scoping-call-optimization-title": "Beschleunigung deiner Digitalisierung",
    "scoping-call-optimization-description":
      "Unser Scoping Call dient zur Identifizierung und Ausarbeitung möglicher Digitalisierungsprojekte und Möglichkeiten zur Optimierung in bereits implementierten Digitalisierungsprozessen - wie können Sie Ihre bestehenden Anwendungsfälle effizienter gestalten oder neue digitale Geschäftsmodelle aufbauen? ",
    "scoping-call-who-are-we-title": "Über uns",
    "scoping-call-who-are-we-description":
      "Cloud Spezialisten mit Passion und 100% Fokus auf AWS - das sind wir von globaldatanet. Bereits seit 2017 arbeiten wir an Digitalisierungskonzepten mit Cloud-Lösungen und wurden von AWS als “Advanced Consulting Partner” ausgezeichnet. Aufgrund unseres Serverless-First-Ansatzes können wir auch Ihre individuellen Anforderungen schnell in Angriff nehmen und gemeinsam Ihr Projekt in Wochen statt Monaten umsetzen. Zu den unzähligen Vorteilen bei der Nutzung von verwalteten AWS Services gehört, des Weiteren, der Pay-As-You-Go-Ansatz. Bedeutet: Für die Services wird nur die Nutzung berechnet, die von Ihnen tatsächlich verwendet wird. Dadurch entstehen keine hohen Kosten für Resourcen, die aktuell gar nicht verwendet werden. Wir stellen das Business in den Fokus und wie Ihr Unternehmen optimiert werden kann - globaldatanet bringt Sie auf die nächste Stufe der Digitalisierung.",
    "scoping-call-procedure-title": "Deine neue digitale Strategie",
    "scoping-call-heading1": "Stand der Dinge/Reife",
    "scoping-call-description1":
      "Auf welchem Stand befinden sich Ihre Anwendungen zum momentanen Zeitpunkt mit Hinblick auf digitalisierte und Cloud-basierte Lösungen?",
    "scoping-call-heading2": "Individuelle Herausforderungen",
    "scoping-call-description2":
      "Vor welchen Herausforderungen stehen Sie, die ein optimiertes und maximal effizientes Arbeiten zur Zeit unmöglich machen?",
    "scoping-call-heading3": "Prioritäten/Priorisierung",
    "scoping-call-description3":
      "Welche Handlungsfelder ergeben sich aus den identifizierten Herausforderungen? ",
    "scoping-call-heading4": "Solutions",
    "scoping-call-description4":
      "Welcher Lösungsansatz bietet sich für Ihre individuellen Use Cases an?",
    "scoping-call-heading5": "Guideline/Roadmap/Next Steps",
    "scoping-call-description5":
      "Was sind die nächsten Schritte, die Sie direkt umsetzen können? Welches sind die nächsten Schritte, die wir gemeinsam gehen auf Ihrem Weg in Richtung Digitalisierung Ihres Unternehmens? ",
    "scoping-call-contact-heading":
      "Lass uns deinen nächsten Schritt besprechen",
    "opensource-heading": ".Open Source",
    "opensource-heading-description":
      "Wir sind stolz darauf, Open-Source-Projekte mit der Community zu teilen. Wir glauben daran, dass die Entwicklung von Open Source dazu beiträgt, bestehende Lösungen zu ergänzen und zu verbessern und deren Einsatz so auch einen Mehrwert für die Community und unsere Kunden schafft.",
    "opensource-projectsHeading": "Open Source projekte",
    "opensource-action-button": "Entdecke unsere Projekte",
    "opensource-project-action-button": "Entdecke dieses Projekt",
    "opensource-projectheader1": "AWS Firewall Factory",
    "opensource-projectdesc1":
      "In einfachen Szenarien und für kleinere Anwendungen ist es sehr einfach, WAFs auf individueller Basis zu implementieren. In größeren Umgebungen mit Dutzenden oder sogar Hunderten von Anwendungen ist es jedoch ratsam, eine zentrale Verwaltung und Automatisierung anzustreben. Mit dieser einfachen Lösung können Sie Ihre Web Application Firewalls bereitstellen, aktualisieren und bereitstellen, während Sie sie zentral über AWS Firewall Manager verwalten.",
    "opensource-projectheader2": " Cost Reporter",
    "opensource-projectdesc2":
      "Ein Lambda, die einen täglichen Kosten- und Trendbericht an Slack sendet. Es hilft bei einer der drei FinOps-Phasen: Informieren. Es kann Ihnen jeden Tag einen Bericht senden / nur wenn die Kosten steigen / nur wenn die Kosten einen Schwellenwert überschreiten, je nach Ihrer Konfiguration. Dies ist hilfreich, um neue Projekte im Auge zu behalten, wenn sich die Architektur schnell ändert, aber auch für bestehende Projekte, wenn Sie die Kosten im Auge behalten wollen.",
    "opensource-projectheader3": " ECS Scheduler",
    "opensource-projectdesc3":
      "Herunterfahren und Starten von Containern zur Kosteneinsparung in Entwicklungsumgebungen. ECS-Dienste mit oder ohne konfigurierte Autoskalierung können mit diesem Lambda heruntergefahren werden. Standardmäßig werden sie um 7:00 Uhr morgens gestartet und um 21:00 Uhr abends gestoppt, was zu einer Kosteneinsparung von ca. 40 % für ECS in Entwicklungsumgebungen führen sollte.",
    "opensource-projectheader4": "WAF Notification",
    "opensource-projectdesc4":
      "AWS WAF bietet jetzt frühzeitige Benachrichtigungen über bevorstehende Regelaktualisierungen für Ihre verwalteten Regelgruppen über Amazon Simple Notification Service (Amazon SNS). Diese Automatisierung sendet Slack-Benachrichtigungen über verwaltete Regelaktualisierungen für eine bestimmte verwaltete Regelgruppe.",
    "opensource-projectheader5": "AWS Marketplace Automation",
    "opensource-projectdesc5":
      "Die Lösung kümmert sich um neu hinzugefügte Konten, bestehende Konten, die SUSPENDED sind oder wenn das spezifische Tag für Lizenzen in einem Konto aktualisiert wird - dass jede bestehende Lizenz geteilt oder von diesem Konto entfernt wird.",
    "opensource-projectheader6": "AWS ORGTOOL",
    "opensource-projectdesc6":
      "Mit diesem Tool können Sie die Struktur Ihrer AWS-Organisationen und Policies in eine Json-Datei exportieren und die Struktur und SCPs in eine andere AWS-Organisation importieren. Während des Imports und Exports wird eine Protokolldatei geschrieben, so dass die Prozesse nachverfolgt werden können.",
    "opensource-description":
      "Wir bei globaldatanet glauben fest an den Nutzen von OpenSource. OpenSource ist ein Raum, an dem Menschen mit einer Leidenschaft für Innovation und einem Drang zur Verbesserung ihr Wissen teilen und beisteuern können, um gemeinsam Tools und Lösungen für das Gemeinwohl aller zu entwickeln. Die Gemeinschaft von Entwicklern und Konsumenten profitiert dabei von einer Vielzahl an gemeinsamen Zielen. Deshalb möchte auch unser Team einen Beitrag leisten. Besonders durch unsere Expertise sind wir davon überzeugt, dass wir einen guten Beitrag leisten können, um nützliche Werkzeuge und Lösungen bereitzustellen, die das tägliche Leben jedes Einzelnen einfacher und effizienter machen.",
    "opensource-description-header": "Contribution",
    "sustainability-heading": ".Nachhaltigkeit",
    "sustainability-action-button": "Eden Reforestation",
    "sustainability-green-it-header": "Green IT",
    "sustainability-green-it-description":
      "Unter dem Begriff „Green IT” ist die Verbindung von Digitalisierung und Nachhaltigkeit zusammengefasst. Die Energie für Cloud-Dienste wird in Zukunft vollständig aus erneuerbaren Energiequellen stammen, die Kühlung der Rechenzentren soll möglichst naturnah erfolgen und in Zukunft immer weniger Energie verbrauchen. Heutzutage werden Lösungen auch unter dem Aspekt der Nachhaltigkeit geplant und entworfen. Serverless Architekturen versprechen dabei eine hocheffiziente und bedarfsorientierte Verwendung von IT-Infrastruktur, die nur nach dem tatsächlichem Verbrauch abgerechnet wird.",
    "sustainability-eden-header": "Eden Reforestation",
    "sustainability-eden-description":
      "Eden ist in neun Projektnationen tätig, pflanzt fast eine Million Bäume pro Tag und hält das Pflanzniveau auch dann aufrecht, wenn es mit Bürgerunruhen, extremen Wetterbedingungen, wilden Tieren oder anderen Störungen konfrontiert ist. Während sie die Baumkronen wiederherstellen, kehren Laub, Wasser und Wildtiere zurück und die Natur beginnt zu heilen. Unterdessen erhalten Gemeinschaften, die mit extremer Armut konfrontiert sind, neue Möglichkeiten für wirtschaftliche Selbstversorgung, verbesserte Gesundheit und Bildung.",
    "sustainability-description-header": "1000 Bäume jeden Monat",
    "sustainability-description":
      "Um einen Beitrag dazu zu leisten, die Welt ein wenig grüner und besser zu machen, haben wir uns entschieden, mit Eden Reforestation Projetcs, einem der weltbesten Wiederaufforstungspartner zusammenzuarbeiten und werden ab sofort mit jeden Monat 1000 neue Bäume pflanzen.",
    "sustainability-heading-description":
      "Nachhaltigkeit ist für uns bei globaldatanet nicht nur ein Wort – wir leben es. Wir lieben es, nachhaltige, effiziente und serverlose Architekturen zu entwickeln, die Energie und Ressourcen nur dann verbrauchen, wenn sie wirklich benötigt werden.",
    "community-heading": ".Community",
    "community-heading-description": "Voneinander Lernen und zusammen wachsen",
    "community-description-header": "Engagement",
    "community-description":
      "Sich in der Community zu engagieren ist eine großartige Möglichkeit, Ideen und Wissen zu teilen, mit Gleichgesinnten in Kontakt zu kommen und spannende Gedanken über neue Themen auszutauschen. Vor allem die Möglichkeit, sich mit Gleichgesinnten auszutauschen, neue Perspektiven zu gewinnen und neue Dinge zu lernen, ist der Grund, warum wir uns in der Community so wohl fühlen und wir denken, dass es wichtig ist, der Community etwas zurückzugeben, indem wir Inhalte beisteuern und Gedanken auf dieselbe Weise teilen.",
    "community-communitiesHeading": "Communities",
    "community-serverless-summit":
      "Der Serverless Summit ist die größte Serverless-Konferenz der Welt. Es ist eine jährliche und globale Veranstaltung, gegründet von globaldatanet. Ein Ort, an dem Serverless-Experten aus der ganzen Welt auf der Bühne stehen, ihre Erkenntnisse teilen und die neuesten Trends und Innovationen präsentieren. Die Veranstaltung zeichnet sich durch die sehr hohe Qualität der Sessions, die Interaktion mit der Community und die Vielfalt an innovativen Formaten aus. Neben Tech & Use Case Talks, Podiumsdiskussionen, Workshops und Serverless-Experten, die für Fragen im Hintergrund zur Verfügung stehen, haben wir auch die Serverless Awards ins Leben gerufen, das weltweit einzige Format, bei dem die besten Lösungen von der Community und führenden Industrieunternehmen ausgezeichnet werden. Unser Ziel ist es, Werte zu schaffen, einzigartige Erlebnisse zu schaffen und zu lernen und zu verbinden.",
    "community-hug":
      "HashiCorp User Groups (HUG) sind die Eckpfeiler der Community: regelmäßige Treffen, die von Einheimischen abgehalten werden, freiwillige Organisatoren, die sich leidenschaftlich für HashiCorp-Tools und Wissens- und Erfahrungsaustausch einsetzen. Wir sind Gründer und Organisatoren der HUG in Hamburg - ein vierteljährliches Treffen voller interessanter Gespräche und Einblicke rund um HashiCorp, ein Ort, um Wissen zu teilen, Gedanken auszutauschen und sich mit Gleichgesinnten zu vernetzen.",
    "community-awscommunityday":
      "AWS Community Day-Veranstaltungen sind von der Community geleitete Konferenzen, bei denen die Veranstaltungslogistik und die Inhalte von führenden Vertretern der Community geplant, beschafft und bereitgestellt werden. Community Day-Veranstaltungen bieten eine Peer-to-Peer-Lernerfahrung und bieten Entwicklern einen Ort, an dem sie AWS-Wissen auf die von ihnen bevorzugte Weise erwerben können: von anderen.",
    "community-cdkday":
      "Der CDK Day ist eine jährliche Communityveranstaltung über AWS CDK, cdktf, projen und cdk8s. Verschiedene Speaker mit unterschiedlichem Hintergrund repräsentieren das gesamte Spektrum an Erfahrungen,von “meinen ersten Erfahrungen mit CDK” bis hin zu “meinen Erfahrungen als Top-10-Core-AWS-CDK-Mitarbeiter”. - jeder ist willkommen. Der Fokus in den Sessions liegt auf Demos! Mehr Code und weniger Folien.",
    "community-awsusergroups":
      "AWS-fokussierte Benutzergruppen sind eine weltweit Gemeinschaft von Entwicklern und Führungskräften, die durch ihr gemeinsames Interesse vereint sind, Erfahrungen auszutauschen, neue Services und Best Practices kennenzulernen, Beziehungen aufzubauen, und sogar Freundschaften zu schließen. Unser Team ist aktives Mitglied der AWS-Communities in Hamburg, Berlin und Belgrad und trägt mit Vorträgen, Podiumsdiskussionen oder sogar Event-Sponsoring bei.",
    "community-button": "Besuche",
    "security-packages-heading": "Grundlagen der Cloud-Sicherheit",
    "security-packages-button": "Book now",
    "security-packages-description": "Ein Tages Workshop - Level 200",
    "security-packages-offer-heading": "Inhalt",
    "security-packages-offer-description":
      "In diesem Workshop erstellen Sie einen einfachen Cloud-Service und wählen geeignete Sicherheitsmaßnahmen aus, um ihn mit AWS-Diensten wie AWS Identity and Access Management (IAM), AWS Config, Amazon GuardDuty und AWS CloudTrail zu schützen. Ziel ist es, Cloud-Ingenieuren praktisches Wissen zu vermitteln, wie sie Sicherheitsprobleme untersuchen und eine Sicherheits-Baseline auf AWS aufbauen können. Der Workshop baut auf den Beispielen der AWS-Sicherheitsreferenzarchitektur auf und stellt Ingenieuren und Cloud-Kompetenzzentrumsteams eine Reihe von Best-Practice-Mustern vor, wie Anwendungen in AWS gesichert werden können.",
    "header-securitypackage-fundamentals":
      "Grundlagen der Cloud-Sicherheit Workshop",
    "security-keytopicsHeading1": "Advice",
    "security-keytopicsDescription1":
      "Guidance for Cloud Competence Center & Cloud Engineers",
    "security-keytopicsHeading2": "Security Baseline",
    "security-keytopicsDescription2":
      "Best Practices Patterns for Security & Data isolation using account separation",
    "security-keytopicsHeading3": "Hands-On Workshop",
    "security-keytopicsDescription3": "Live examples",
    "security-keytopicsHeading4": "Q&A Session",
    "security-keytopicsDescription4":
      "Get your questions answered by an expert.",
    "security-packages-buy-price-head": "Ready?",
    "security-packages-buy-price-info":
      "Vereinbare jetzt einen Termin für Deinen Workshop, lerne einen unserer Experten kennen und bringe Dein Team auf das nächste Level.",
    "security-packages-buy-price-info-details":
      "pro Workshop, bis zu 3 Teilnehmer - bei mehr bieten wir Ihnen einen individuellen Preis auf Anfrage.",
    "header-securitypackage-serverless-saas": "Serverless SaaS Workshop",
    "serverless-saas-packages-heading": "Serverless SaaS Workshop",
    "serverless-saas-packages-description": "One Day Workshop - Level 200",
    "serverless-saas-packages-offer-heading": "Content",
    "serverless-saas-packages-offer-description":
      "In this workshop you will be building a multi-tenant Software-as-a-Service (SaaS) solution using AWS Serverless Services, such as Amazon API Gateway, Amazon Cognito, AWS Lambda, Amazon DynamoDB, AWS CodePipeline, and Amazon CloudWatch. The goal is to provide SaaS developers and architects with working code that illustrates how to design and deliver a multi-tenant SaaS solution on AWS. The workshop is built on the foundation of a Serverless SaaS Reference Architecture and presents architects and developers with a range of new multi-tenant design considerations.",
    "serverless-saas-keytopicsHeading1": "Serverless Web Application",
    "serverless-saas-keytopicsDescription1":
      "Introduction of all components....",
    "awards-description":
      "Durch die Arbeit in der Community haben wir mehrere Auszeichnungen von Amazon Web Services und Hashicorp erhalten. Diese Auszeichnungen werden für Mentoren und technische Enthusiasten und aufstrebende Vordenker verliehen, die leidenschaftlich gerne ihr Wissen teilen und sich mit der technischen Community vernetzen.",
    "awards-header": "Community Auszeichnung",
    "community-openit":
      "Open IT ist die größte serbische IT-Konferenz für Studenten. An zwei Tagen treffen sich 300 Studenten und 20 Unternehmen . Mit unser Unterstützung, versuchen, wir die IT-Szene in Serbien einen Schritt weiter zu bringen! OpenIT wurde mit der Idee gegründet, jungen IT-Studenten bei ihrem nächsten Schritt zu helfen, ihr Potenzial zu entwickeln, ihnen die Möglichkeit geben, ihre Leidenschaft in verschiedenen Bereichen der IT zu entdecken, die neuesten Technologien kennenzulernen und sich mit Menschen und Unternehmen zu vernetzen.",
    "podcast-description":
      "Höre Experten zu, wie sie über moderne Herausforderungen und Chancen innerhalb der Cloud sprechen und ihre vielfältigen Potenziale für Unternehmen jeder Größe aufzeigen.",

    "podcast-why-title": "Podcast trifft Leidenschaft",
    "podcast-why-description": `Wir sind leidenschaftliche Innovations-Antreiber. Ab und zu hören wir aber auch gerne Podcasts. Deswegen haben wir beschlossen, beides zu kombinieren und dir den Serverless World Podcast zu präsentieren.
Einfach mal abschalten und zuhören oder im Hintergrund laufen lassen, egal wann und wo. Du entscheidest.`,

    "first-podcast-title": "001 - Digitalisierung in der Logistik",
    "first-podcast-description":
      "In dieser Folge diskutiert unser CEO Marc Schroeter mit Janek Lünstedt, Sr. Enterprise Account Manager bei Amazon Web Services, über die Herausforderungen und Chancen der Digitalisierung in der Logistikbranche. Diese Folge ist auf Deutsch.",
    "first-podcast-language": "Deutsch",
    "first-podcast-attendees":
      "Marc Schröter (globaldatanet), Janek Luenstedt (Amazon Web Services)",
    "second-podcast-title": "002 - Das Ohr am Netz - Episode 10",
    "second-podcast-description":
      "In dieser Folge wird über Private Cloud, Public Cloud und Multi Cloud diskutiert. Wo liegen die Vor- und Nachteile? Für welche Unternehmen lohnen sich Private Cloud Angebote?. Diese Folge ist auf Deutsch.",
    "second-podcast-language": "Deutsch",
    "second-podcast-attendees":
      "Marc Schröter (globaldatanet), Felix Höger (EuroCloud), Silke Kanes (Scopevisio AG), Henrik Hasenkamp (gridscale)",
    "third-podcast-title": "003 - Intelligente Dokumentenverarbeitung mit KI",
    "third-podcast-description":
      "Gemeinsam mit Larissa Becka, AWS Specialist für Machine Learning und AI, spricht unser CEO Marc Schröter über die Chancen und gängigen Anwendungsfälle von IDP.",
    "third-podcast-language": "Deutsch",
    "third-podcast-attendees":
      "Marc Schröter (globaldatanet), Larissa Becka (Amazon Web Services)",
    "podcast-subscribe": "Abonnieren",
    "view-podcast": "Podcast ansehen auf ",
    "read-more": "weiterlesen",
    "solutions-read-more": "erfahre mehr",
    "solutions-page-subtitle":
      "Erfahre mehr über unsere cloud-nativen Lösungen, erweitere deine Kenntnisse durch unsere spannenden Workshops oder nutze eine unserer ausgezeichneten SaaS Lösungen, die dich und dein Unternehmen voranbringen.",
    "download-now": "Jetzt Herunterladen",
    "download-now-report": "Jetzt Whitepaper Herunterladen",
    "report-testimonial": "Testimonial",
    "report-details": "Details",
    "report-use-case": "Entdecken Sie spannende Anwendungsfälle und Lösungen",
    "report-key-topics": "Key Topics",
    "cookie-text-one": "Deine Privatsphäre ist uns wichtig!",
    "cookie-text-two":
      "Wir setzen auf unserer Website Cookies ein. Einige sind essentiell, während andere helfen unser Onlineangebot zu verbessern.",
    "cookie-text-three": "Nähere Hinweise bekommst du in unserer ",
    "cookie-text-four": "Datenschutzerklärung",
    "cookie-btn": "Ich akzeptiere",
    "cookie-btn-decline": "Ablehnen",
    "report-main-description":
      "Lade Dir spannende Cloud-Reports mit den neuesten Marktanalysen und Trends herunter, die Aufschluss darüber geben, wie Cloud-Dienste und -Technologien Dein Geschäft beschleunigen und zu Innovationen führen werden.",
    "report-download-btn": "Report herunterladen",
    "report-first-name": "Vorname*",
    "report-last-name": "Nachname*",
    "report-email": "Geschäftliche Email*",
    "register-to-download-report": "Jetzt eintragen & Report erhalten",
    "search-header": "suche",
    "search-title": "Suche",
    "search-description":
      "Entdecke, die neuesten Erkenntnisse und Erfolgsgeschichten auf unserer Seite, indem Du durch unsere spannenden Blog-Beiträge, detaillierten Fallstudien, bahnbrechende Lösungen und umfassende Reports durchsuchst.",
    "search-button": "Starte deine Entdeckung...",
    "saas-solution-header": "SaaS-Anwendungs-entwicklung",
    "saas-solution-subtitel":
      "Die nächste Generation von SaaS-Anwendungen, well-architected und entwickelt von globaldatanet",
    "saas-solution-intro-header": "SaaS in der Cloud",
    "saas-solution-intro-description":
      "SaaS-Anbieter stehen unter enormem Innovations- und Wettbewerbsdruck. Sie müssen in der Lage sein, schnell auf den sich verändernden Markt zu reagieren und ständig neue Funktionen bereitzustellen. Die Kunden erwarten ein nahtloses Onboarding, hohe Serviceverfügbarkeit ohne Ausfallzeiten, benutzer-, volumen- und funktionsabhängige Preise, gleichbleibend hohe Geschwindigkeit und ein Höchstmaß an Sicherheit und Datenisolierung.",
    "saas-solution-intro-description2":
      " Wir helfen Dir beim Aufbau von SaaS-Architekturen, die von einem Betriebs- und Kundenerlebnis umgeben sind, das die Agilität und die Software-as-a-Service-Grundsätze verwirklicht, die für den Erfolg eines SaaS-Angebots entscheidend sind. Durch die Nutzung der neuesten AWS- und SaaS-Konzepte, Prinzipien, allgemeinen Muster, Strategien und bewährten Verfahren, die für die Entwicklung, Erstellung und Verwaltung von mandantenfähigen SaaS-Anwendungen auf AWS entscheidend sind, bieten wir Dir das technische Fachwissen, dass Du für jede Phase Deines SaaS-Geschäfts benötigst.",
    "saas-solution-details-header": "Dein SaaS Accelerator",
    "saas-solution-details-01-header": "Cloud-native SaaS-Entwicklung",
    "saas-solution-details-01-description":
      "Entwicklung von entkoppelten Microservices für Deine SaaS-Anwendungsebene unter Nutzung der Vorteile von Cloud Computing, Serverless- und Container-Technologie.",
    "saas-solution-details-02-header": "SaaS-Cloud-Architektur",
    "saas-solution-details-02-description":
      "Moderne, dynamisch skalierende, hochverfügbare, ereignisgesteuerte, serverlose Architektur, Pay-per-Use mit flexibler Tier-basierter Infrastruktur für gepoolte, hybride und siloisierte Microservices.",
    "saas-solution-details-03-header": "SaaS-Modernisierung",
    "saas-solution-details-03-description":
      "Revitalisiere Deine SaaS-Anwendungen mit einem Modernisierungsprogramm, das eine moderne, agile Anwendungsarchitektur schafft. Beginne mit den geschäftlichen Anforderungen und konzentriere Dich dann auf die Technologien.",
    "saas-solution-details-04-header": "SaaS-Optimierung",
    "saas-solution-details-04-description":
      "Implementierung verschiedener Optimierungen zur Verbesserung der Skalierbarkeit, der Kosten, der Leistung, der Agilität, der betrieblichen Effizienz Ihrer SaaS-Anwendung und der Sicherheit Deiner tenants.",
    "saas-solution-details-05-header": "SaaS-Transformation",
    "saas-solution-details-05-description":
      "Transformierung Deiner Anwendung zu einer SaaS-Applikation. Implementierung von Shared Services für DevOps und Bereitstellungen, Verwaltung und Überwachung, Onboarding, Identität, Abrechnung und Zählung, Metriken und Analysen.",
    "saas-solution-process-header": "Prozess",
    "saas-solution-process-button": "Jetzt starten",
    "saas-solution-process-01-header": "Intro call",
    "saas-solution-process-01-description":
      "Vorstellung von globaldatanet, Besprechung Deiner SaaS-Anwendung und spezifischen Anforderungen, Erkundung, wie unsere SaaS-Expertise und Lösungen Dir helfen können, Deine Ziele zu erreichen.",
    "saas-solution-process-02-header": "Discovery call",
    "saas-solution-process-02-description":
      "Wir erkunden Deine Ziele und den genauen Bedarf, diskutieren Herausforderungen und entwerfen erste Lösungsansätze.",
    "saas-solution-process-03-header": "Strategie-Workshop",
    "saas-solution-process-03-description":
      "Interaktiver Workshop zur Ermittlung potenzieller Lösungen, die nach dem Discovery Call zusammengeführt wurden.",
    "saas-solution-process-04-header": "Story Mapping",
    "saas-solution-process-04-description":
      "Erstellung eines visuellen Backlogs auf der Grundlage von User-Goals und -Stories mit Userstories zu jedem Schritt. Planung des ersten MVP-Release.",
    "saas-solution-process-05-header": "Proof of Concept (POC)",
    "saas-solution-process-05-description":
      "Mit Low-/No-Code-Tools entwickeln wir eine erste POC-Version mit geringem Umfang, um eine Validierung des Anwendungsfalls durchzuführen.",
    "saas-solution-process-06-header": "SaaS foundation",
    "saas-solution-process-06-description":
      "Wir implementieren alle Funktionen und Dienste, die für das Onboarding, die Authentifizierung, die Verwaltung, den Betrieb und die Analyse einer mandantenfähigen Umgebung erforderlich sind.",
    "saas-solution-process-07-header": "Minimum Viable Product (MVP)",
    "saas-solution-process-07-description":
      "Entwicklung einer Version Deiner SaaS-Applikation, die nur die wesentlichen Funktionen enthält, um die Bedürfnisse Ihrer Kunden durch Feedback zu validieren.",
    "saas-solution-process-08-header": "Innovate",
    "saas-solution-process-08-description":
      "Sei der Zeit immer einen Schritt voraus. Häufige Veröffentlichung von Funktionen und schnelle Reaktion auf den Markt. Hinzufügen von datengesteuerten und KI-basierten Funktionen.",
    "saas-review-header": "SaaS Review",
    "saas-review-description":
      "Die mandantenfähigkeit von SaaS-Anwendungen erfordert von den Architekten ein Umdenken in Bezug auf Sicherheit, Betriebseffizienz, Stabilität und Agilität ihrer SaaS-Umgebung. Buche jetzt ein kostenloses SaaS-Review, um einen vollständigen Überblick über Deine SaaS-Readiness zu erhalten.",
    "saas-review-header-01": "Neueste Konzepte",
    "saas-review-description-01":
      "Erhalte eine Analyse Deines aktuellen SaaS-Status. Durch den Einsatz neuester SaaS-Designprinzipien, gängiger Muster und Strategien identifizieren wir Bereiche für Verbesserungen.",
    "saas-review-header-02": "Best practices",
    "saas-review-description-02":
      "Durch den Einsatz bewährter SaaS-Verfahren stellen wir sicher, dass Deine SaaS-Anwendung sicher, zuverlässig, effizient, kostengünstig und nachhaltig arbeitet.",
    "saas-review-header-03": "Verbesserungsplan",
    "saas-review-description-03":
      "Erstellung eines Aktionsplans und einer Roadmap mit allen Erkenntnissen, wie du Deine SaaS-Anwendung und Dein Unternehmen optimieren kannst.",
    "saas-review-button": "Jetzt buchen",
    "saas-form-header": "Entdecke Deine SaaS Anforderungen",
    "rewind-aws-title": "Das Neueste von der re:Invent 2023",
    "rewind-aws-description":
      "Wenn du AWS nutzt, um dein Unternehmen voranzubringen, aber die neuesten Ankündigungen der re:Invent 2023 nicht kennst, hast du hier die Chance, das nachzuholen! 🚀 \n\nDiese Sitzung umfasst Amazons generativen KI-Assistenten für Unternehmen und Entwickler sowie eine Reihe von Innovationen wie neue Datenbankfunktionen, Entwickler-Tools, CloudOps, Security und vieles mehr. 🔥",
    "rewind-ctrace-title":
      "Automatisierte ML-Pipelines für die Abfallwirtschaft",
    "rewind-ctrace-description":
      "Dieser Anwendungsfall könnte für jedes Unternehmen relevant sein, das ML in seinen Betrieb auf AWS integriert! \n\n Mohammad Moallemi, unser AWS Cloud & MLOps Engineer & Tobias Richard, ML Engineer/Data Scientist zeigen, wie der Aufbau einer soliden Basis die Betriebskosten senken und die Tür für zukünftige ML-Anwendungsfälle öffnen kann. ✨ \n\n c-trace GmbH ist ein Keyplayer in der Entsorgungslogistikbranche, der mit seinen innovativen IT- und Logistiklösungen Maßstäbe setzt. ♻️ \n\n Mit dem Ausbau der Aktivitäten im Bereich des maschinellen Lernens wurde die manuelle Konfiguration von Modelltrainings und die Pflege der Trainingsinfrastruktur zu einer zeitraubenden Herausforderung, die das Team davon abhielt, sich auf wichtigere Aufgaben zu konzentrieren, die einen größeren Geschäftswert schaffen. Wir schufen die Grundlage für die serverlose MLOps-Plattform, die Self-Service-Trainingspipelines mit allen notwendigen Funktionen bereitstellt. Von der Verwendung versionierter Datensätze bis hin zur automatischen Modellversionierung! Insgesamt führt dies zu einer effektiveren Ressourcenzuweisung und einer neuen Cloud-nativen Landschaft, die die Einführung neuer Anwendungsfälle und Ideen erleichtert. ✅",
    "rewind-2024-trend-title": "Tech-Trends 2024 und die Zukunft der Technik",
    "rewind-2024-trend-description":
      "Wir haben die re:Wind mit einer inspirierenden Keynote über die Tech-Trends für 2024 und die allgemeine Zukunft der Technik eröffnet! 🔮 \n\n Zu Beginn eines jeden neuen Jahres lassen wir das vergangene Jahr und seine Höhepunkte Revue passieren. So haben wir zum Beispiel den aktuellen Stand der Anwendung von Cloud-Technologien besprochen, sich entwickelnde Rollen und Qualifikationsanforderungen aufgrund von KI-Fortschritten sowie allgemeine Branchentrends, die sich auf die Arbeitskräfte auswirken, diskutiert. 🚀 \n\n Aber wir blicken nicht nur zurück. Unser Ziel ist es, uns auf das zu konzentrieren, was vor uns liegt. Ausgehend von den Fortschritten des Jahres 2023 können wir nur erwarten, dass das Jahr 2024 sowohl aus technologischer als auch aus geschäftlicher Sicht noch bedeutender sein wird.",
    "rewind-electric-charging-infrastructure-title":
      "Datenplattform für die deutsche Elektroladeinfrastruktur",
    "rewind-electric-charging-infrastructure-description":
      "In dieser Session geht es um unsere beeindruckende Zusammenarbeit zur Unterstützung des Ausbaus der E-Ladeinfrastruktur in Deutschland, mit der Nationalen Leitstelle Ladeinfrastruktur unter dem Dach der NOW GmbH. 💜 \n\n Die Leitstelle ist nun in der Lage, alle erforderlichen Meldungen für die laufende Deutschlandnetz-Initiative einfach und automatisiert zu erstellen und dabei die strengen Richtlinien des Bundesministeriums für Digitales und Verkehr einzuhalten. ✅ \n\n Diese Lösung zeigt, wie wichtig moderne Cloud-Technologien für die Digitalisierung der Prozesse öffentlicher Einrichtungen sind. ✨",
    "rewind-ai-powered-sales-title":
      "KI-gestützte Vertriebspipeline für ISV Private Equity",
    "rewind-ai-powered-sales-description":
      "BID Equity hat sich darauf spezialisiert, Juwelen in der Softwarebranche zu acquirieren und sie zu europäischen Marktführern zu machen. Eine besondere Herausforderung besteht darin, dass diese Unternehmen nicht aktiv verkauft werden; stattdessen müssen sie gefunden werden, aber es fehlen oft aussagekräftige öffentliche Daten. 🔍💎 \n\n An dieser Stelle setzen wir an und stellen eine KI-gesteuerte Lösung vor, die das Internet durchforstet, potenzielle Softwareunternehmen zuverlässig klassifiziert und die Branchen und Technologien des Unternehmens mithilfe von Natural Language Processing (NLP) analysiert. 📈🌐 \n\n Wir zeigen auch, wie die Entwicklung von Machine Learning, einschließlich der Erstellung, des Trainings und des Einsatzes von Modellen, durch den Einsatz von SageMaker Studio skalierbar gemacht werden kann und somit die Kosten mit einer automatischen Skalierungsfunktion gesenkt werden können, die das Hosting von ML-Modellen verbessert.✅",
    "rewind-iot-data-platform-title":
      "Aufbau der IoT- und Datenplattform für eine der größten Flotten der Welt",
    "rewind-iot-data-platform-description":
      "Dieser wegweisende Cloud-Anwendungsfall unterstützt die Hapag-Lloyd AG beim Betrieb der intelligentesten Containerflotte der Welt. 💯 \n\n Diese Container liefern wertvolle Informationen über IoT-Tracking-Geräte, wie Standort, Zeit, Temperatur und Gesundheitszustand, die von verschiedenen Lösungsanbietern generiert werden. Daraus ergeben sich riesige Datenmengen, die von mehreren technischen Teams - von Big Data bis Smart Container - gesammelt und harmonisiert werden müssen, bevor sie in wertvolle Erkenntnisse umgewandelt werden können. 📈 \n\n Wir haben dieses Problem gelöst, indem wir eine hochleistungsfähige Cloud-Plattform entwickelt haben, die diese riesigen Datenmengen nahtlos und kosteneffizient aufnimmt, indem sie je nach eingehenden und ausgehenden Daten schnell skaliert. Die Daten werden verarbeitet, an verschiedene Datenströme gesendet und nahezu in Echtzeit in wertvolle Erkenntnisse umgewandelt. 🚀",
    "rewind-ccoe-title":
      "Aufbau eines Cloud Center of Excellence (CCoE) in einer regulierten Branche",
    "rewind-ccoe-description":
      "Wir geben exklusive Einblicke, wie man sich in einer regulatorischen Branche zurechtfindet und warum die Zusammenarbeit mit öffentlichen Einrichtungen bedeutet, Menschen und Unternehmen in Einklang zu bringen. \n\n Die BARMER, eine der größten Krankenkassen in Deutschland, entwickelt innovative Versorgungsangebote und ermöglicht einen schnellen Zugang zu Innovationen in Medizin, Prävention, Rehabilitation und Pflege. Seit 2020 arbeiten wir eng mit ihrem IT-Team zusammen, um eine sichere Cloud-Plattform für die Entwicklung und den Betrieb von Diensten bereitzustellen. \n\n Was diesen Cloud-Anwendungsfall noch spezieller macht, sind die streng regulierten Richtlinien der Gesundheitsbranche, die alle berücksichtigt werden mussten, einschließlich der Endpunktüberwachung, während gleichzeitig die Projektagilität erhalten blieb.\n\n Wir richteten eine sichere AWS-Umgebung mit mehreren Konten ein, die auf bewährten Sicherheitspraktiken und der Einhaltung von Standards wie der General Data Protection Regulation (GDPR) basiert. Als einer der 3 AWS Security Competency Partner in der DACH-Region hoffen wir, mit dieser Session den öffentlichen Sektor für AWS zu begeistern! 🔥",
    "rewind-title": "re:Wind 2024",
    "rewind-subtitle":
      "5 Cloud Case Studies, die die einzigartigen Herausforderungen unserer Kunden zeigen und wie wir sie gelöst haben, um ihre Geschäftsziele zu erreichen. Darüber hinaus haben wir uns mit aktuellen Tech-Trends befasst und die wichtigsten Neuigkeiten von der re:Invent 2023 behandelt.",
    "rewind-button": "Watch now",
  },
};
